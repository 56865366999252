import { createContext, useContext, useState } from "react";

interface Props {
    children: React.ReactNode;
}


const defaultCurrency = 'PLN'

const CurrencyContext = createContext<{
    currency: string,
    changeCurrency: (curr: string) => void
}>({
    currency: defaultCurrency,
    changeCurrency: (curr: string) => {}
});

const CurrencyProvider: React.FC<Props> = ({ children }) => {
    const [currentCurrency, setCurrenctCurrency] = useState<string>(defaultCurrency)

    return (<CurrencyContext.Provider
    value={{
        currency: currentCurrency,
        changeCurrency: setCurrenctCurrency
    }}>
    {children}
    </CurrencyContext.Provider>)
}
const useCurrency = () => useContext(CurrencyContext)

export {CurrencyContext, CurrencyProvider, useCurrency};
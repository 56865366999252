import React, {useContext, useEffect, useState} from 'react';
import {CalendarDay} from "../../models/CalendarDay.model";
import classNames from "classnames";

import './CalendarSelector.scss';
import {TimeSlot} from "../../models/TimeSlot.model";
import {InputText} from "primereact/inputtext";
import {CartContext, Types} from "../../contexts/reducers";
import DateTimeSelector from "./DateTimeSelector";
import LoadableImage from "../Image/LoadableImage";
import {getImageUrl} from "../../utils/Api.service";
import { FaCalendarWeek, FaGift } from 'react-icons/fa';
import {useTranslation} from "react-i18next";
import { usePostHog } from 'posthog-js/react';

type CalendarSelectorProps = {
    calendarDays?: CalendarDay[],
    selectedTimeSlot?: TimeSlot,
    voucherValidity: number
}

const CalendarSelector = (props: CalendarSelectorProps) => {
        const { state, dispatch } = useContext(CartContext);
        const {t} = useTranslation()
        const posthog = usePostHog()
        const [canVoucherBeSelected, setCanVoucherBeSelected] = useState<boolean>(false)
        const [voucherStyles, setVoucherStyles] = useState<string>('hidden')
        const [calStyles, setCalStyles] = useState<string>('')
        const [viewDate, setViewDate] = useState(new Date())
        useEffect(() => {
            setSelectionState(state.orderType)
            document.title = t("Wybór terminu");
            window.scrollTo(0, 0)
            if (props.selectedTimeSlot?.type === 'voucher') {

                setVoucherStyles('opacity-100')
                setCalStyles('hidden opacity-0')
            } else {
                setVoucherStyles('hidden opacity-0')
                setCalStyles('opacity-100')
            }

        }, []);
        const handleTimeClick = (date: string, time: string, calendarId: string) => {
            posthog.capture('merch timeslot selected')
            dispatch({type: Types.UpdateTimeslot, payload: {
                type: 'timeslot',
                    day: date,
                    time: time,
                    duration: state.cart.duration,
                    calendarId: calendarId
            }})
        }
        useEffect(
            () => {
                setCanVoucherBeSelected(!state.cart.discounts?.find(x => x.type === 'MONEY_VOUCHER' || x.type === 'BUNDLE_VOUCHER'))
            },
            [state]
        )
        const handleVoucherSelection = (voucher: boolean) => {
            setSelectionState(voucher ? 'voucher' : 'booking')
            if (voucher) {
                setCalStyles('opacity-0')
                setVoucherStyles('opacity-0 absolute')
                setTimeout(() => {
                    setCalStyles('hidden opacity-0');
                    setVoucherStyles('opacity-100')
                }, 200);
                posthog.capture('merch selected_order_type voucher')
            } else {
                setVoucherStyles('opacity-0')
                setCalStyles('opacity-0')
                setTimeout(() => {
                    setVoucherStyles('hidden opacity-0');
                    setCalStyles('opacity-100')
                },200);
                posthog.capture('merch selected_order_type booking')

            }

            if (voucher) {
                if (!state.booking || state.booking.type !== 'voucher' || !state.booking.owner) {
                    dispatch({type: Types.UpdateTimeslot, payload: {type: 'voucher', owner: '', duration: state.cart.duration ?? 0}})
                }
            } else {
                //dispatch({type: Types.RemoveTimeslot, payload: null})
            }
        }
        const onVoucherOwnerUpdate = (data: any) => {
            dispatch({type: Types.UpdateTimeslot, payload: {type: 'voucher', owner: data.target.value, duration: state.cart.duration ?? 0}})
        }
        const [selectionState, setSelectionState] = useState<string|null>()

        useEffect(() => {
            if (selectionState && selectionState !== '') {
                handleVoucherSelection(selectionState === 'voucher')
            }
        },[selectionState])

        return (
            <div className={"m-auto bg-white"}>
                {!canVoucherBeSelected && <div className={"relative transition-all duration-300 ease-in-out " + calStyles}>
                    <DateTimeSelector viewDate={viewDate} onMonthUpdated={(date,single) => {}} skipHalfHours={false} forceSingle={false} onDateTimeSelected={handleTimeClick} selectedTimeSlot={props.selectedTimeSlot} calendarDays={props.calendarDays}/>
                </div>}
                {canVoucherBeSelected && <>
                <div className={"px-4 pt-2 h-full"}>
                    <div className="flex my-4">
                        <div className="mx-auto mb-4 sm:mb-0 w-1/2  sm:inline-block text-center">
                            <div className={classNames({
                                "relative animated-button mx-2 relative h-full rounded leading-none cursor-pointer hover:drop-shadow-lg hover:bg-secondary-dimmed px-4 py-2 shadow-lg flex items-center justify-center": true,
                                "animated-button-small bg-grey-100 border-2 border-grey-100": selectionState === 'booking',
                                "animated-button-small bg-secondary-dimmed border-2 border-secondary": selectionState === 'voucher',
                                "animated-button-large border-2 border-secondary": selectionState === '',
                            })}
                                 onClick={() => {handleVoucherSelection(true); dispatch({type:Types.SelectOrderType, payload:'voucher'});}}
                            >
                                <div className="flex">
                                <div className="icon text-secondary mr-8"><FaGift size={50}/></div>
                                <div className="leading-4 sm:leading-10 align-middle flex items-center">{t('Kup jako voucher prezentowy')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-auto mb-4 sm:mb-0 w-1/2  sm:inline-block text-center">
                            <div className={classNames({
                                "animated-button mx-2 relative h-full leading-none rounded hover:bg-secondary-dimmed hover:drop-shadow-lg cursor-pointer px-4 py-2 shadow-lg flex items-center justify-center": true,
                                "animated-button-small bg-grey-100 border-2 border-grey-100": selectionState === 'voucher',
                                "animated-button-small bg-secondary-dimmed border-2 border-secondary": selectionState === 'booking',
                                "animated-button-large border-2 border-secondary": selectionState === '',
                            })}
                            onClick={() => {handleVoucherSelection(false); dispatch({type:Types.SelectOrderType, payload:'booking'});}}
                            >
                                 <div className="flex">
                                <div className="icon text-secondary mr-8"><FaCalendarWeek size={50}/></div>
                                <div className="leading-4 sm:leading-10 align-middle flex items-center">{t('Zarezerwuj termin realizacji')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"bg-white pb-6 transition-all duration-300 ease-in-out mt-4 md:mt-8 " + (selectionState === '' ? 'hidden' : voucherStyles)}>
                        <div className="mx-0 px-2 ">
                            <div className="text-xl font-bold mb-4">{t('Dane osoby realizującej voucher')}</div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field w-full md:w-1/2 ml-1">
                                    <div className="mr-2">
                                        <label htmlFor="firstname">{t('Imię i nazwisko')}</label>
                                        <InputText id="firstname" type="text" onInput={onVoucherOwnerUpdate}
                                                   value={props.selectedTimeSlot?.type === 'voucher' ? props.selectedTimeSlot.owner : ''}
                                                   maxLength={50}/>
                                    </div>
                                    <div>{t('Vouchery są ważne')} {props.voucherValidity} {t('dni od daty zakupu')}.</div>
                                    <div className={"mt-6"}>
                                        <div className="text-xl font-bold">{t('Zawartość vouchera')}</div>
                                        {state.cart.items.filter(item => item.unit !== "PIECE").map((kit, index) => {
                                            return (<div  key={index}>
                                                <div className="flex mt-2">
                                                <span className="w-full text-dark">
                                                   <div className={"w-full"}>
                                                       <div className={"inline-block"}>
                                                        <LoadableImage previewAvailable={false} classes={"inline-block max-w-16 max-h-16 mr-2"} thumbnailData={''} imageSrc={getImageUrl(kit.sku, 'large')}/>
                                                       </div>
                                                        <div className="inline-block text-left text-lg font-bold text-dark leading-16">
                                                            {kit.quantity > 1 && kit.quantity + "x "}    {kit.name} {(kit.upsell?.length ?? 0 ) > 0 && <span className={"text-md text-secondary"}>{t(' + dodatki')}</span>}
                                                        </div>
                                                   </div>
                                                </span>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <React.Fragment>
                <div className={"relative transition-all duration-300 ease-in-out mt-4 md:mt-8 " + (selectionState === '' ? 'hidden' : calStyles)}>
                    <DateTimeSelector viewDate={viewDate} onMonthUpdated={(date,single) => {}}  skipHalfHours={false} forceSingle={false} onDateTimeSelected={handleTimeClick} selectedTimeSlot={props.selectedTimeSlot} calendarDays={props.calendarDays}/>
                </div>
            </React.Fragment></>}
            </div>
        );
    }
;

export default CalendarSelector;

import React from "react"
import {BsArrowRepeat} from "react-icons/bs";
import {MdClose} from "react-icons/md";
import {FaFacebook} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const SuccessPage = (props: any) => {
    const dismissButtonVisible = (window.self !== window.top)
    const success = ((new URLSearchParams(window.location.search)).get('status') ?? 'CONFIRMED' ) === 'CONFIRMED'
    const tenantId = (new URLSearchParams(window.location.search)).get('tenantId')
    const { t } = useTranslation();

    const dismissWindows = () => {
        // eslint-disable-next-line
        parent.postMessage({type: "bookitone-dismiss"}, '*')
    }

    const getFbAddress = () => {
        if (tenantId === '40bea4c2-27aa-45c2-9cc6-6a715df237cd') {
            return 'https://www.facebook.com/strzelnicapawlow'
        } else if (tenantId ==='1ec74d51-29d4-4fd1-a91f-0b284e74b5ff') {
            return 'https://www.facebook.com/strzelnicahistoryczna'
        } else if (tenantId === '31ec411c-1f30-4b85-af62-462af4b7ca5a') {
            return 'https://www.facebook.com/profile.php?id=100080973917637';
        } else if (tenantId === '56474afd-d607-1cfa-01e2-3cc949b80aa3') {
            return 'https://www.facebook.com/profile.php?id=100063539290738';
        } else if (tenantId === 'ba571035-073d-4faa-b29e-9d85b840945c') {
            return 'https://www.facebook.com/bastion.zgorzelec'
        } else if (tenantId === 'f0473ca0-0992-4f5c-8a8f-b04ee4cdd0d7') {
            return 'https://www.facebook.com/profile.php?id=61552192345651';
        } else if (tenantId === '96fbf968-be86-42dc-b826-de6f5e358ac5') {
            return 'https://www.facebook.com/strzelnica.tactical.core';
        } else if (tenantId === '3a41041e-ec98-4bb2-8676-2a9a988ca5e6') {
            return 'https://www.facebook.com/profile.php?id=61556358483944';
        }  else {
            return 'https://www.facebook.com/bookitone/'
        }
    }
    return (
        <React.Fragment>
            <div className={"max-w-1530px  mx-auto  m-4 relative bg-white py-16  rounded"}>
                { dismissButtonVisible && <div className={"absolute top-2 right-2 md:top-4 md:right-4 p-2 hover:bg-grey rounded cursor-pointer z-10"} onClick={dismissWindows}>
                    <MdClose size={25}/>
                </div>}
                <img className={"mx-auto my-16 max-w-1/3 max-h-200"} src={success ? "/success.svg" : "/failed.svg"} alt={success ? "Udana płatność" : "Nieudana płatność"}/>
                <div className={"text-center"}>
                    <span className={"text-4xl font-bold"}> {success ? t("Twoje zamówienie zostało opłacone!") :  t("Niestety twoja płatność się nie powiodła.")}</span>
                </div>
                <div className={"text-center mt-6"}>
                    <span className={"text-2xl font-bold text-grey-400"}>{success ?  t("Otrzymasz za chwilę email z potwierdzeniem zamówienia."):  t("Nie pobrano środków z Twojego konta.")}</span>
                </div>
                <div className={"text-center mt-6"}>
                    {success ?
                    <a href={getFbAddress()} target="_top">
                    <button
                        className={"text-primary rounded flex justify-center text font-bold mx-auto mb-4 max-w-full border-secondary border-4 mt-14 p-3"}
                    >
                        <div>
                        <span className={"pi p-button-icon-left text-secondary mt-1"}><FaFacebook size={"30"}/></span>
                        </div>
                        <span className={"flex-auto text-xl ml-4 mt-1"}>{t('Odwiedź nas na Facebooku')}</span>
                    </button>
                    </a>
                        :
                        <a href={"/"}>
                            <button
                                className={"text-primary rounded flex justify-center text font-bold mx-auto mb-4 max-w-full border-secondary border-4 mt-14 p-3"}
                            >
                                <div>
                                    <span className={"pi p-button-icon-left text-secondary mt-1"}><BsArrowRepeat size={"30"}/></span>
                                </div>
                                <span className={"flex-auto text-xl ml-4 mt-1"}>{t('Ponów zamówienie')}</span>
                            </button>
                        </a>}
                </div>
            </div>
        </React.Fragment>
    )
}
export default SuccessPage
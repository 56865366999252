import { ConfirmPopup } from "primereact/confirmpopup"
import React, {useContext, useState, useEffect, useRef} from "react"
import { MdClose } from "react-icons/md";
import Footer from "../components/Footer/Footer";
import PurchaseNavigation from "../components/PurchaseNavigation/PurchaseNavigation";
import {CartContext, Types} from "../contexts/reducers";
import classNames from "classnames";
import { getDetails, getRangeItems } from "../utils/Api.service";
import ReactGA from 'react-ga4';
import Ranges from "../components/Ranges/Ranges";
import {RangeGroupProduct, RangeVariant, SellableProduct, Variant} from "../models/Product.model";
import RangeQtySelector from "../components/Ranges/RangeQtySelector";
import RangeTimeSelector from "../components/Ranges/RangeTimeSelector";
import Confirmation from "../components/Confirmation/Confirmation";
import { BackButton } from "../components/Ranges/BackButton";
import RangeCart from "../components/Ranges/RangeCart";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { useParams } from "react-router-dom";
import { AvailabilityPopup } from "../components/Ranges/Availability/AvailabilityPopup";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../components/LanguageSelector/LanguageSelector";
import { useCurrency } from "../contexts/CurrencyProvider";
import { usePostHog } from "posthog-js/react";
import { Tag } from "../models/Tags.model";
import { useTenant } from "../contexts/TenantProvider";
import ReactPixel from 'react-facebook-pixel';
import { useCookiesConsent } from "../contexts/CookiesProvider";
import { useGrowthBook } from "@growthbook/growthbook-react";
import { isBay, isLane } from "../utils/RangeTypeDetector";

const RangePage = () => {
    const [closePageWarningVisible, setClosePageWarningVisible] = useState<boolean>(false);
    const {statistics} = useCookiesConsent()
    const { t } = useTranslation();
    const gb = useGrowthBook()
    const posthog = usePostHog()
    const {tenant, setTenant, setTenantId} = useTenant()
    const {changeCurrency} = useCurrency()
    const {currency} = useCurrency()
    const dismissWindows = () => {
        posthog.capture('exit')
        try{
            localStorage.removeItem('cart')
        } catch (e) {}
        // eslint-disable-next-line
        parent.postMessage({type: "bookitone-dismiss"}, '*')
    }
    const [activeIndex, setActiveIndex] = useState(0)
    const [hideCover, setHideCover] = useState<boolean>(true);
    const [isAvailabilityPopupOpened, setIsAvailabilityPopupOpened] = useState<boolean>(false);

    const [products, setProducts] = useState<SellableProduct[]>([])
    const [allProducts, setAllProducts] = useState<SellableProduct[]>([])

    const [dismissAndCartVisible, setdismissAndCartVisible] = useState<boolean>(true)
    const [selectedVariant, setSelectedVariant] = useState<Variant & RangeVariant>()
    const [selectedProduct, setSelectedProduct] = useState<SellableProduct & RangeGroupProduct>()
    const [productToPreviewAvailability, setProductToPreviewAvailability] = useState<SellableProduct & RangeGroupProduct>()
    const [productSkuToPreviewAvailability, setProductSkuToPreviewAvailability] = useState<string>('')

    const {state, dispatch} = useContext(CartContext);
    const [finalVariant, setFinalVariant] = useState<Variant>()
    const [quantity, setQuantity] = useState<number>(1)
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [loadingScreenMessage, setLoadingScreenMessage] = useState<string>("");
    const [loadingScreenLink, setLoadingScreenLink] = useState<{ link: string, text: string }>({link: "", text: ""});
    const [legalRequirementFulfilled, setLegalRequiremedFulfilled] = useState<boolean>(false)
    const scrollableRef = useRef(null)
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const selectedOption = useRef<null | HTMLDivElement>(null)
    const [inputPromoCode, setInputPromoCode] = useState<string>()

    const [tags, setTags] = useState<Tag[]>([]);

    //@ts-ignore
    let {sku, rangeType} = useParams()

    useEffect(() => {
        if (statistics && tenant) {
             if (tenant.facebookPixelId) {
                ReactPixel.init(tenant.facebookPixelId, undefined, {autoConfig: true, debug: false})
                ReactPixel.pageView();
            }
            ReactGA.initialize([
                {trackingId: tenant.googleAnalyticsId, gaOptions: {cookieFlags: 'secure;samesite=none'}}])
        }
    }, [statistics, tenant])

    const toggleTag = (x: Tag) => {
        if (selectedTags.find(st => st.key === x.key)) {
            setSelectedTags([])
        } else {
            setSelectedTags([x])
        }
    }

    useEffect(() => {
        if (selectedTags.length > 0) {
            if (selectedTags[0].key === 'shooting') {
                setProducts(allProducts.filter(x =>(x.tags?.length ?? 0) === 0 || (x.tags?.findIndex(x => x.key === selectedTags[0].key) ?? -1) > -1))
            } else {
                setProducts(allProducts.filter(x =>(x.tags?.findIndex(x => x.key === selectedTags[0].key) ?? -1) > -1))
            }
        }
    },[selectedTags])

    const [menuItems,setMenuItems] = useState([
        {label: t('Osie'), active: true},
        {label: t('Szczegóły'), active: false},
        {label: t('Termin'), active: false},
        {label: t('Podsumowanie'), active: false}
    ]);

    useEffect(() => {
        setMenuItems([
            {label: t('Osie'), active: true},
            {label: t('Szczegóły'), active: Boolean(selectedProduct) && Boolean(selectedVariant)},
            {label: t('Termin'), active: Boolean(selectedProduct) && Boolean(selectedVariant) && Boolean(finalVariant)},
            {label: t('Podsumowanie'), active: Boolean(selectedProduct) && Boolean(selectedVariant) && Boolean(finalVariant) && state.cart.items.length > 0 && Boolean(state.cart.items.find(x => x.timeslot))},
        ])
    }, [selectedProduct, selectedVariant, finalVariant, state.cart.items])
    const canVisitStep = (index: number) => {

        if (index === 1) {
            return selectedProduct && selectedVariant
        }
        if (index === 2) {
            return selectedProduct !== undefined && selectedVariant && finalVariant
        }
        if (index === 3) {
            return selectedProduct !== undefined && selectedVariant && finalVariant && state.cart.items.length > 0 && state.cart.items.find(x => x.timeslot)
        }
        return true;
    }
    const updateActiveIndex = (index: number, updateInParent = true, skipValidation = false) => {
        if (activeIndex > 1 && index <= 1) {
             //@ts-ignore
            const laneProduct = selectedProduct.variants.find(x => isLane(x))
            //@ts-ignore
            if (laneProduct) {
                //@ts-ignore
                setSelectedVariant(laneProduct)
            } else {
                //@ts-ignore
                setSelectedVariant(selectedProduct.variants.find(x => isBay(x)))
            }
        }
        if (skipValidation || canVisitStep(index)) {
            if (scrollableRef.current) {
                //@ts-ignore
                scrollableRef.current.scrollTo(0, 0)
            }
            if (index === 0) {
                document.title = t("Osie");
                ReactGA.send({hitType:"pageview", page: '/ranges', title: t("Osie")})
                dispatch({type:Types.RemoveSelectedRange, payload: null})
            } else if (index === 1) {
                document.title = t("Liczba stanowisk");
                ReactGA.send({hitType:"pageview", page: '/ranges/range', title: t("Liczba stanowisk")})
            } else if (index === 2) {
                document.title = t("Termin");
                ReactGA.send({hitType:"pageview", page: '/calendar', title: t("Wybór terminu")})
            } else if (index === 3) {
                posthog.capture('merch begin_checkout')
                document.title = t("Posumowanie");
                ReactGA.send({hitType:"pageview", page: '/checkout', title: t("Podsumowanie")})
            }
            if (updateInParent) {
                window.parent.postMessage({type: 'index_changed', prevStep: activeIndex, newStep: index}, '*')
            }
            setHideCover(false)
            scrollToTop()
            posthog.capture('$pageview')
            setTimeout(function () {
                setActiveIndex(index)
            }, 200)
            setActiveIndex(index)
            setTimeout(function () {

                setTimeout(function () {
                    setHideCover(true)
                }, 300)
            }, 500)
        }
    }
    const handleBackEvent = (event: any) => {
        if (event.data && event.data.type && event.data.type === 'updateIndex' ) {
            back()
        }
    }
    useEffect(() => {
        window.addEventListener("message",handleBackEvent,false);
        return () => window.removeEventListener('message', handleBackEvent)
    },[activeIndex])
    useEffect(() => {
        if (finalVariant) {
            
            updateActiveIndex(2, true)
        }
    }, [finalVariant])

    useEffect(() =>{
        if (isDataLoading) {
            document.getElementById('data-loading')?.scrollIntoView({behavior:'smooth'})
        }
    }, [isDataLoading])

   
    const onPackageSelected = (product: SellableProduct & RangeGroupProduct) => {
        if (!selectedProduct || product.id !== selectedProduct.id) {
            setSelectedProduct(product)
            const laneProduct = product.variants.find(x => isLane(x))
            if (laneProduct) {
                //@ts-ignore
                setSelectedVariant(laneProduct)
            } else {
                //@ts-ignore
                setSelectedVariant(product.variants.find(x => isBay(x)))
            }
            updateActiveIndex(1, true)
        }
        if (selectedProduct && selectedProduct.id === product.id) {
            updateActiveIndex(1, true)
        }
    }

    // const onRangeTypeSelected = (variant: Variant & RangeVariant) => {
    //     setSelectedVariant(variant)
    //     updateActiveIndex(2, true)
    // }
    const onRangeDetailsSelected = (variant: Variant, quantity: number) => {
        if (!selectedVariant?.product.variants.find(x => x.product.id === variant.product.id)) {
            //@ts-ignore
            setSelectedVariant(selectedProduct?.variants.find( x=> x.product.variants.find(y => y.product.id === variant.product.id)))
        }
        setFinalVariant(variant)
        setQuantity(quantity)

        dispatch({type: Types.Update, payload: {item:{
            sku: variant.product.sku,
            quantity: quantity,
            name: variant.product.name,
            price: variant.product.price,
            taxRate: variant.product.taxRate,
            duration: variant.product.duration ?? 60,
            
        }, index:0}})
        ReactPixel.track('AddToCart', {
            contentName: variant.product.name,
            content_ids:[variant.product.sku],
            content_type: 'product',
            value:  (variant.product.price / 100.00),
            currency: currency
        })
        setTimeout(function () {
            updateActiveIndex(2,true)
        }, 300)
       

    }
    const back = () => {
        if (activeIndex !== 0) {
            updateActiveIndex(activeIndex - 1, true)
        }
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0});
      };
    const onForward = () => {
        updateActiveIndex(activeIndex + 1, true)
    }
    const onEnterSummary = () => {
        updateActiveIndex(3, true);
    }
    const possibleTags = [
        {key: 'shooting', label: t('Strzelanie')},
        {key: 'throw', label: t('Siekiery')}
    ]
    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search)
        updateActiveIndex(0, true);
        const tennatId = urlParams.get('tenantId') ?? '00000000-0000-0000-0000-000000000000'
        gb.setAttributes({
            tenant: tennatId
        })
        setTenantId(tennatId)
        posthog.group('tenant', tennatId)
        getDetails().then(tenantInfo => {
            setTenant(tenantInfo)
            
            changeCurrency(tenantInfo.defaultCurrency)
        })
        const promoCode = urlParams.get('voucher')
        if (promoCode) {
            setInputPromoCode(promoCode)
        }

        getRangeItems().then((res) => {
            setAllProducts(res)
            if (res.findIndex(x => (x.tags?.findIndex(y => y.key === 'throw') ?? -1) !== -1) !== -1) {
                setTags(possibleTags)
                setSelectedTags([possibleTags[0]])
            }
            if (rangeType) {
                setSelectedTags([possibleTags[1]])

                posthog.group('visitor','website')
            } else {
                setProducts(res.filter(x => x.tags?.length === 0 || (x.tags?.findIndex(x => x.key === 'throw' || x.key === 'training') ?? -1) === -1))
                
                if (sku) {
                    posthog.group('visitor','direct_link')
                    let product = res.find(x => x.sku === sku)
                    if (product) {
                        //@ts-ignore
                        onPackageSelected(product)
                        updateActiveIndex(1, true, true)

                    } else {
                        res.forEach((p) => {
                            //@ts-ignore
                            p.variants.forEach((variant :Variant & RangeVariant) => {
                                if (variant.product.sku === sku) {
                                    //@ts-ignore
                                    setSelectedProduct(p)
                                    setSelectedVariant(variant)
                                    updateActiveIndex(1, true, true)
                                
                                }
                            })
                    
                        })
                    }
                } else {
                    posthog.group('visitor','website')
                }
            }
    })
    posthog.capture('merch session_started ranges')
        const source = urlParams.get('source')  ?? undefined
        if (!state.cart.id) {
            dispatch({type: Types.Init, payload: { source: source}})
        }
    }, [])
    

    const onShowAvailabilityTable = (product: SellableProduct & RangeGroupProduct, variantSku: string) => {
        setProductToPreviewAvailability(product)
        setProductSkuToPreviewAvailability(variantSku)
        setIsAvailabilityPopupOpened(true)
    }

    useEffect(() => {
        if (selectedProduct) {
            updateActiveIndex(1, true)
        }
    }, [selectedProduct])
    return (
    <React.Fragment>
        <div className={"px-2 md:px-0 max-w-1530px bg-white mx-auto rounded my-2 mx-4 md:my-4 relative overflow-y-hidden main-container"}>
        <ConfirmPopup
            target={document.getElementById('dismissButton') ?? undefined}
                        visible={closePageWarningVisible}
                        onHide={() => setClosePageWarningVisible(false)}
                        acceptLabel={t("Opuść stronę")}
                        rejectLabel={t("Pozostań na stronie")}
                        message={t("Czy na pewno chcesz zamknąć?")}
                        icon="pi pi-exclamation-triangle"
                        accept={dismissWindows}
            reject={() => setClosePageWarningVisible(false)}
        />
        <AvailabilityPopup
            isOpened={isAvailabilityPopupOpened}
            variantSku={productSkuToPreviewAvailability}
            onClose={() => setIsAvailabilityPopupOpened(false)}
            range={productToPreviewAvailability}
        />
                    <div
                        id={"dismissButton"}
                        className={"absolute top-0 right-2 top-2 md:right-4 p-2 hover:bg-grey rounded cursor-pointer z-20  lg:top-4 lg:right-6"}
                        onClick={() => setClosePageWarningVisible(true)}
                    >
                        <MdClose size={25}/>
                    </div>
                    <div id={"mainContent"} className={"bg-grey-100 rounded w-full"}>
                    <LanguageSelector supportedLanguages={tenant?.supportedLanguages ?? []}/>
                    {/* {!tenantInfo?.useProductionSettings && <div className={"text-red px-8 bg-white"}>
                    {t('Niniejszy sklep nie został jeszcze uruchomiony. Złożenie i opłacenie zamówienia możliwe będzie w ciągu kilku dni.')}
                    </div>} */}
                    <PurchaseNavigation
                        activeIndex={activeIndex}
                        onSelect={(e) => updateActiveIndex(e.index)}
                        model={menuItems}
                    />
                     {activeIndex > 0 && <BackButton onBackClicked={back} classes={"top-2"}/>}
                    </div>
                    <div className={classNames({
                        "px-0 pb-2 relative lg:ml-20  flex flex-col": true
                    })}
                    ref={scrollableRef}
                    style={{flexGrow: 1, overflowY: 'auto', verticalAlign: 'top'}}
                    >
                        <div style={{flexGrow: 1}}>
                         <div className={classNames({
                            "w-full h-full transition duration-200 ease-linear absolute": true,
                            "opacity-100 z-50i ": isDataLoading,
                            "opacity-0": !(isDataLoading),
                            '-z-10': hideCover
                        })}><LoadingScreen message={loadingScreenMessage} link={loadingScreenLink}/></div>
                        
                        <div className={"w-full lg:w-3/4  min-h-vh55 inline-block"}>
                            {activeIndex === 0 &&
                                <div className={"mr-4"}>
                                    <div className={"md:py-2 py-2 pb-2 overflow-x-auto whitespace-nowrap"}>
                                            {tags.map(x => (
                                                <div
                                                    key={x.key}
                                                    className={
                                                        classNames({
                                                            "text-xs  md:text-base  mb-2 inline-block cursor-pointer hover:bg-secondary rounded-full hover:text-white px-4 py-1 mx-1 md:mx-2 border-2 border-secondary": true,
                                                            "bg-secondary text-white": selectedTags.length > 0 && selectedTags.find( st => st.key === x.key),
                                                            "bg-white": selectedTags.length === 0 || !selectedTags.find( st => st.key === x.key),
                                                            "text-secondary": selectedTags.length === 0 || !selectedTags.find(st => st.key === x.key)
                                                        })}
                                                        ref={selectedTags.length > 0 && selectedTags.find( st => st.key === x.key) ? selectedOption : undefined}
                                                    onClick={() => {toggleTag(x)}}
                                                >{t(x.label)}
                                                    </div>
                                            ))}
                                        </div>
                                <Ranges
                                        packages={products}
                                        setDismissButtonVisible={setdismissAndCartVisible}
                                        onPackageSelected={onPackageSelected}
                                    />
                                </div>
                            }
                              {/* {activeIndex == 1 &&
                             <div className={"lg:mr-4"}>

                                <RangeOrderTypeSelector product={selectedProduct} onRangeTypeSelected={onRangeTypeSelected} onBack={back}/>
                                </div>} */}
                             {activeIndex === 1 &&
                             <div className={"mr-4"}>
                                    <div className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"></div>
                                <RangeQtySelector 
                                variant={selectedVariant} 
                                parent={selectedProduct}
                                onForward={onRangeDetailsSelected}
                                onShowAvailability={onShowAvailabilityTable}
                                onChangeLegalRequirement={setLegalRequiremedFulfilled}
                                isLegalRequiremendChecked={legalRequirementFulfilled}
                                />
                                </div>}
                                {activeIndex === 2 && finalVariant && selectedVariant && 
                                <div className={""}>
                                    <div className="px-2 md:px-3 text-2xl md:text-4xl font-bold">{t("Wybierz termin rezerwacji")}</div>
                                    <RangeTimeSelector 
                                    onUpdateSelectedQuantity={(quantity) => setQuantity(quantity)} 
                                    parentVariant={selectedVariant}
                                    onBack={back}
                                     selectedVariant={finalVariant}
                                     selectedQuantity={quantity}
                                     onForward={onEnterSummary}
                                     calendars={selectedVariant?.product?.calendars ?? []}
                                     />
                                    </div>}
                                    {activeIndex === 3 && 
                                    <React.Fragment>
                                       <div className="px-2 md:px-3 text-2xl md:text-4xl font-bold">{t("Podaj szczegóły zamówienia")}</div>

                                        <Confirmation
                                        className="lg:mt-4 lg:mr-4"
                                        onUpdateStepIndex={setActiveIndex}
                                        selectedTimeslot={state.booking}
                                        setIsDataLoading={setIsDataLoading}
                                        openVoucherInput={() => {}}
                                        onSetLoadingScreenMessage={setLoadingScreenMessage}
                                        onSetLoadingScreenLink={setLoadingScreenLink}
                                        useProductionSettings={tenant?.useProductionSettings ?? false}
                                        isRangePurchase={true}
                                        />
                                    </React.Fragment>}
                        </div>
                        
                        <div className={classNames({
                            "w-full lg:w-1/4  mt-1 lg:sticky top-0 inline-block align-top": true,
                            "hidden": !dismissAndCartVisible
                        })} >
                            <RangeCart parentVariant={selectedVariant}
                            onForward={onForward} 
                            activeIndex={activeIndex}
                            isForwardButtonVisible={activeIndex === 2 }
                            isFinalIndex={activeIndex === 3}
                            inputCoupon={inputPromoCode}
                            />

                        </div>
                        </div>
                

                        <Footer/>
                    </div>

        </div>

    </React.Fragment>
    
    )
}

export default RangePage
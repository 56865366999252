import React, {useEffect, useState} from "react";
import { OrderSummary } from "../../models/Order.model";
import { Dialog } from "primereact/dialog";

type ManageReservationDialogProps = {
    orderId: string,
    tenantOrderId: string,
    currentBooking: {
        datetime: string,
        duration: number
    },
    wasOrderRescheduled: boolean,
    wasOrderFulfilled: boolean,
    isOrderCancelled: boolean,
    isOrderInNext24h: boolean,
    isDialogOpened: boolean,
    onDialogClose: () => void,

}
const ManageReservationDialog = (props: ManageReservationDialogProps) => {
    return <>
    <Dialog 
    visible={props.isDialogOpened}
            className="package-info-dialog relative max-h-full md:h-vh90 h-full md:w-9/10 bg-white "
            onHide={() => props.onDialogClose()}
            dismissableMask={true}
            
            closable={true}>

    </Dialog>
    </>
}

export default ManageReservationDialog
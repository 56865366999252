import React, {useEffect, useState} from 'react';
import {Range as RangeView} from "./Range";
import {RangeGroupProduct, SellableProduct} from "../../models/Product.model";
import ReactGA from "react-ga4";
import { useCurrency } from '../../contexts/CurrencyProvider';
import SkeletonRangeView from './SkeletonRangeView';

type RangesProps = {
    packages: SellableProduct[],
    setDismissButtonVisible: (visible: boolean) => void,
    onPackageSelected: (product: SellableProduct & RangeGroupProduct) => void
}
const Ranges = (props: RangesProps, {}) => {
    const [items, setItems] = useState<SellableProduct[]>([])
    const {currency} = useCurrency()

    // @ts-ignore
    useEffect(() => {
            // @ts-ignore
            setItems(props.packages.filter(x => x.type !== "SIMPLE").sort((a,b) => {return(a.position ?? 0) > (b.position ?? 0 )? 1 : -1}))
            if (props.packages.length > 0) {
                ReactGA._gtag("event", "view_item_list", {
                    "item_list_id": "products",
                    "item_list_name": "Products",
                    "items": props.packages.map(product => {
                        return {
                            "item_id": product.sku,
                            "item_name":product.name,
                            "currency":currency,
                            "price": (product.price / 100.00)
                        }
                })
                })
        }

    }, [props.packages])


    return (
        <React.Fragment>
                        <div className={"w-full  pt-1 md:pt-4"}>
                          {items.length === 0 &&
                            <SkeletonRangeView/>}

            {
                items.map(item => (item.type !== 'SIMPLE' && item.type !== 'EXTENSION') && <RangeView
                    key={item.sku}
                    package={item}
                    onSetSelectedProduct={props.onPackageSelected}
                    />)
            }
                        </div>

        </React.Fragment>
    );
};

export default Ranges;

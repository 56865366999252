import React from "react"
import { BiLeftArrowAlt } from "react-icons/bi";

type BackButtonProps = {
    onBackClicked: () => void,
    classes?: string,
    index?: number
}
export const BackButton = (props: BackButtonProps) => {

    return (<React.Fragment>
         <div className={"z-10 absolute left-0 md:left-6 top-0 hover:bg-grey-200 cursor-pointer px-2 py-1 rounded bg-white flex  " + (props.classes ?? "" )} onClick={props.onBackClicked}> 
         <BiLeftArrowAlt size={25} className="my-1"/>
          </div>

    </React.Fragment>)
}
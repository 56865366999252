import React from "react";
import './MoreDetailsButton.scss'
import {useTranslation} from "react-i18next";

export type MoreDetailsButtonProps = {
    onButtonClick: () => void,
    isPackageCustom: boolean,
    containerClassName?: string,
    className?: string
}

export const MoreDetailsButton = (props: MoreDetailsButtonProps) => {
    const {t} = useTranslation()
    return (
        <div className={props.containerClassName}>
            <button className={"more-details-button " + props.className + (props.isPackageCustom ? " more-details-custom": " hidden sm:flex")} onClick={props.onButtonClick}>
                <span className={"flex-auto"}>
                    {props.isPackageCustom ? t("Zbuduj zestaw") : t("Szczegóły")}
                </span>
            </button>
        </div>
    )
}

export default MoreDetailsButton
import React, { useContext, useEffect, useState } from "react"
import {MdClose} from "react-icons/md";
import {useTranslation} from "react-i18next";
import { BiCheckCircle, BiChevronLeft, BiError } from "react-icons/bi";
import { FiClock } from "react-icons/fi";
import moment from "moment";
import { getFormattedPrice } from "../utils/PriceUtils";
import { TenantInfo } from "../models/Details.model";
import { getDetails, getOrderDetals } from "../utils/Api.service";
import { CurrencyContext } from "../contexts/CurrencyProvider";
import { getCartItemLabel } from "../utils/UnitUtils";
import { CartContext } from "../contexts/reducers";
import { usePostHog } from "posthog-js/react";
import { OrderSummary } from "../models/Order.model";
import LanguageSelector from "../components/LanguageSelector/LanguageSelector";
import { FaFacebook, FaFacebookF, FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DateTimeSelector from "../components/CalendarSelector/DateTimeSelector";
import { classNames } from "primereact/utils";

const ManageReservationPage = (props: any) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState<string>('PENDING')
    const [isChangeBookingOpened, setIsChangeBookingOpened] = useState<boolean>(false);
    const [orderType, setOrderType] = useState<string>('PACKAGE_RESERVATION')
    const posthog = usePostHog()
    const {t} = useTranslation()
    const { state } = useContext(CartContext);

    const [tenantInfo, setTenantInfo] = useState<TenantInfo>();
    const {changeCurrency, currency} = useContext(CurrencyContext);

    const [order, setOrder] = useState<OrderSummary>()
    const [booking, setBooking] =useState<{date: string, time: string}|null>(null)
    
    const dismissWindows = () => {
        // eslint-disable-next-line
        parent.postMessage({type: "bookitone-dismiss"}, '*')
    }

    const getOrderStatus = (order: any): string => {
        if (order.orderStatus === 'CANCELLED' || order.orderStatus === 'FAILED') {
            return 'CANCELLED'
        }
        if (order.orderStatus == 'PLACED') {
            if (order.payment === null) {
                if (order.finalPrice === 0) {
                    return 'CONFIRMED';
                } else {
                    return 'CANCELLED';
                }
            }
            if (order.payment.status === 'PENDING') {
                if (order.payment.paymentMethod === 'CASH') {
                    return 'ONSITE';
                }
                return 'PENDING';
            }
            
            if (order.payment.status === 'FAILED') {
                return 'CANCELLED'
            }
            if (order.payment.status == 'CONFIRMED') {
                return 'CONFIRMED';
            }
        }
        if (order.orderStatus === 'PROCESSING') {
            if (order.payment.paymentMethod === 'CASH') {
                return 'ONSITE';
            }
            return 'PENDING';
        }
        return "CONFIRMED";
        
    } 

    const assignBooking = (order:OrderSummary): any => {
        if (order.reservationType === "IMMEDIATE") {
            setOrderType("IMMEDIATE");
            setBooking(null);
        } else if ( order.reservationType === "VOUCHER") {
            setOrderType("VOUCHER");
            setBooking(null);
        } else if (order.reservationType === "TIMESLOT") {
            setOrderType("PACKAGE_RESERVATION");
            const date = moment(order.booking.dateTime)
            setBooking({
                date: date.format("dddd, D MMM yyyy"),
                time: date.format('HH:mm')
            })
        } else if (order.reservationType === 'NONE') {
            //@ts-ignore
            if (order.items.find(x => x.timeslot !== null)) {
                setOrderType("RANGE_RESERVATION");
                const bookingPayload = order.items.find(x => x.timeslot !== null)?.timeslot
                if (bookingPayload) {
                    setBooking({date: moment(new Date(bookingPayload.date + "T" +bookingPayload.time + ":00")).format("dddd, D MMM yyyy"), time: bookingPayload?.time })
                }
            } else {
                setOrderType("IMMEDIATE");
                setBooking(null);
            }
        }
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const tennatId = urlParams.get('tenantId') ?? '00000000-0000-0000-0000-000000000000'
        posthog.group('tenant', tennatId)

        const orderId = urlParams.get('orderId') ?? ''
        const order = {
            id: '123',
            tenantOrderId: '1233',
            creationTimestamp: '2024-09-22T16:00:00',
            finalPrice: 12900,
            basePrice: 12900,
            duration: 30,
            orderStatus: 'CONFIRMED',
            reservationType: 'TIMESLOT',
            items: [{
                sku: '123', 
                quantity: 1,
                name: 'Pakiet startowy',
                quantityMultiplier: 1, 
                unit: "PIECE",
                price: 12900,
                priceAfterDiscount: 12900,
                children: [],
                type:'BUNDLE',
                timeslot: null,
                upsell: [],
            }],
            discounts: [],
            payment: {status: 'CONFIRMED', paymentMethod: 'ONLINE'},
            booking: {
                status: 'CONFIRMED',
                dateTime: '2024-09-22T16:00:00',
                duration:30
            },
            currency: 'PLN'
        }
        assignBooking(order)
        setStatus(getOrderStatus(order))
        setOrder(order)

        // getOrderDetals(orderId).then(order => {
        //     assignBooking(order)
        //     setStatus(getOrderStatus(order))
        //     setOrder(order) 

        // })

        // getDetails().then(tenantInfo => {
        //     setTenantInfo(tenantInfo)
           
        //     changeCurrency(tenantInfo.defaultCurrency)

        // })
    }, [])

    const isManageReservationActive = (): boolean => {
        return true
    }
    const canRefundOrder = (): boolean => {
        return true
    }

    const canRescheduleOrder = (): boolean => {
        return true;
    }



    return (
        <React.Fragment>
            <div className={"mx-auto w-full lg:w-600px mt-0 lg:my-4 bg-white  rounded lg:border border-zinc-200 relative flex flex-col" }>
           
                { <div className={"absolute top-1 left-2 md:left-2  lg:top-1 lg:left-6 p-2 text-white hover:text-zinc-700 hover:bg-zinc-200 rounded cursor-pointer z-10"} onClick={() => navigate(-1)}>
                    <BiChevronLeft size={25}/>
                </div>}
                <div className='text-white hover:text-zinc-700'>
                <LanguageSelector supportedLanguages={tenantInfo?.supportedLanguages ?? []}/>
                </div>
                <div className="hero flex flex-col">
                    <div className="w-full h-12 top-0 p-0 m-0 bg-cover bg-center bg-secondary"/>                   
                </div>
                {order && <>
                    <div className="mt-4 mx-6">
                        <span className="text-2xl text-primary font-semibold">{t("Zarządzaj rezerwacją")}: {order.tenantOrderId}</span>
                    </div>
                    {booking && 
                    <div className="mt-2 mx-6">
                        <span className="font-semibold text-primary pb-4">{booking.date} <br/> {booking.time} - {moment(order.booking.dateTime).add(order.duration, 'm').format("HH:mm")}</span>
                    </div>}
                    {orderType === 'VOUCHER' && 
                    <div className="mt-4 mx-6">
                        <span className="font-semibold text-primary text-3xl pb-4">{t("Voucher prezentowy")}</span>
                       
                    </div>}
                   
                    <div className="mt-1 mx-6">
                        <span className="text-zinc-500">{t("Nr zamówienia")}:</span><span className="text-zinc-500 pl-1">{order.tenantOrderId}</span>
                    </div>
                    {order.payment && 
                    <div className="mt-1 mx-6">
                        <span className="text-zinc-500">{t("Metoda płatności")}:</span><span className="text-zinc-500 pl-1">
                            {order.payment.paymentMethod === 'CASH' || order.payment.paymentMethod === 'CARD'  ? t('Na miejscu') : ''}
                            {order.payment.paymentMethod === 'ONLINE' || order.payment.paymentMethod === 'PAYU'  || order.payment.paymentMethod === 'PAYNOW' ? t('Online') : ''}
                            </span>
                    </div>}
                    </>
                }
                <div className="mt-4 mx-6 border-t border-zinc-200"/>

                {
                <div id="manage-reservation" className="mt-6 mx-6">
                    <button 
                    onClick={() => canRescheduleOrder() && setIsChangeBookingOpened(prev => !prev)} 
                    className={classNames({
                        "w-full py-3 text-lg font-medium text-center border rounded": true,
                        "border-secondary bg-secondary  hover:bg-secondary-dimmed hover:text-secondary text-white": canRescheduleOrder(),
                        "border-zinc-300 bg-zinc-100 text-zinc-300 cursor-not-allowed": !canRescheduleOrder()
                    })}
                    >
                        {t("Zmień datę i godzię rezerwacji")}
                    </button>
                    {!canRescheduleOrder() && <span className="text-red">{t("Zmiana terminu rezerwacji online nie jest możliwa.")} <br/> {t("Skonktaktuj się ze strzelnicą w celu modyfikacji rezerwacji")}.</span>}
                    {isChangeBookingOpened && 
                        <div className="mt-8"> 
                            <div className="text-center mb-4">
                                <span className="text-xl font-semibold">{t("Wybrana data")}: </span>
                            </div>
                            {/* <DateTimeSelector onMonthUpdated={(date,single) => {}} calendarDays={[]} onDateTimeSelected={(date, time, calId) => {}} forceSingle={true} skipHalfHours={false} preSelectedSlot={{date: moment(order?.booking.dateTime).toDate(), time:booking?.time ?? "00:00"}}/> */}
                            <div className="border-b border-zinc-200 mt-4"/>
                        </div>
                    }

                    {canRefundOrder() && 
                    <button className="hover:bg-red hover:text-white text-red my-6 w-full py-3 text-lg font-medium text-center border border-red bg-red-dimmed rounded">{t("Anuluj zamówienie")}</button>
                    }
                </div>}

                <div className="mt-6 mx-6 ">
                        <span className="text-zinc-700 font-bold text-xl">{t("Dodatkowe informacje")}</span>
                    </div>
                <div className="mt-2 ml-6 ">
                    <span className="text-zinc-700" dangerouslySetInnerHTML={{__html: tenantInfo?.reservationTerms ?? ""}}></span>
                </div>

                <div className="mt-6 ml-6">
                        <span className="text-zinc-700 font-bold text-xl">{t("Adres")}</span>
                    </div>
                <div className="mt-2 ml-6 mb-8">
                    <span className="text-zinc-700">
                        {tenantInfo?.facilitySettings.address}<br/>
                        {tenantInfo?.facilitySettings.zipCode} {tenantInfo?.facilitySettings.city}
                    </span>
                </div>
               
                <div className="mt-2 text-center mb-6">
                    <div className="inline-block mx-auto"><a href="https://bookit.one" target="_blank" rel="norel" >Powered by<img src="https://bookit.one/wp-content/uploads/2023/12/Bookit-One-2.4b9315f0.png" width={100} className="inline pl-4 mix-blend-difference"/></a></div>
                </div>
            </div>
            
        </React.Fragment>
    )
}
export default ManageReservationPage
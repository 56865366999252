import React, {useEffect, useState} from 'react';
import {Package as PackageView} from "./Package";
import {SellableProduct, SimpleProductAttributes} from "../../models/Product.model";
import ReactGA from "react-ga4";
import {useParams} from "react-router-dom";
import { useCurrency } from '../../contexts/CurrencyProvider';
import { Tag } from '../../models/Tags.model';
import { Button } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

type PackagesProps = {
    items:SellableProduct[]
    packages: SellableProduct[],
    setDismissButtonVisible: (visible: boolean) => void,
    resetSelectedTags: () => void,
    isDataLoaded: boolean,
    preSelectedSku: string,
    resetPreSelectedSku: () => void,
    onTagSelected: (tag:Tag) => void,
    availableTags: Array<Tag>,
}
const Packages = (props: PackagesProps, {}) => {
    const {t} = useTranslation()
    const [additions, setAdditions] = useState<(SellableProduct & SimpleProductAttributes)[]>([])
    const [selectedProduct, setSelectedProduct] = useState<string|null>(null)
    // @ts-ignore
    let {sku} = useParams()
    const {currency} = useCurrency()
    const posthog = usePostHog()

    useEffect(() => {
            // @ts-ignore
        setAdditions(props.items.filter(x => x.type == "SIMPLE").sort((a,b) => {return a.position > b.position? 1 : -1}))
            ReactGA._gtag("event", "view_item_list", {
                "item_list_id": "products",
                "item_list_name": "Products",
                "items": props.packages.map(product => {
                    return {
                        "item_id": product.sku,
                        "item_name":product.name,
                        "currency":currency,
                        "price": (product.price / 100.00)
                    }
            })
            })
        if (props.preSelectedSku) {
            setSelectedProduct(props.preSelectedSku)
            setTimeout(() => {props.resetPreSelectedSku()}, 250)
        }

    }, [props.packages])
    const selectMoneyVouchers = () => {
        posthog.capture('merch money-vouchers-cta')
        window.scrollTo(0, 0)
        props.onTagSelected({key: "vouchers", label: "Vouchery kwotowe"})
    }
    return (
        <React.Fragment>
            {props.packages.filter(item => (item.type !== 'SIMPLE' && item.type !== 'EXTENSION')).length === 0 && !props.isDataLoaded &&
            <div className="mt-8 text-center">
                <span className="text-center block text-lg font-black">Brak produktów w wybranej kategorii.</span>
                <button onClick={props.resetSelectedTags} className="bg-secondary p-4 rounded text-white mt-4 pointer">Sprawdź całą ofertę</button>
            </div>}
            {
                props.packages.filter(x => x.type !== 'SIMPLE' && x.type !== 'EXTENSION').map((item,index) => <React.Fragment  key={item.sku}>
                    <PackageView
                    //@ts-ignore
                    package={item}
                    additions={additions}
                    //@ts-ignore
                    extensions={item.type === 'BUNDLE' ? props.items.filter(x => (x.sku == (item.extensions?.extended?.sku ?? '')) || (x.sku == (item.extensions?.premium?.sku ?? ''))) : []}
                    setDismissButtonVisible={props.setDismissButtonVisible}
                    forceOpen={selectedProduct !== null && item.sku === selectedProduct}
                    unsetForceOpen={() => setSelectedProduct(null)}
                    />
                    {index === 2&& props.packages.length > 2 && props.packages[0].type === 'BUNDLE' && props.availableTags.find(x =>x.key === 'vouchers') &&
                    <div className="bg-secondary w-full py-8 px-4 text-white my-0  gap-8 text-center mx-auto items-center">
                        <div className="mb-4"><span>{t("Nie wiesz z czego chcesz strzelać?")}</span><span className="block lg:inline"> {t("Sprawdź naszą ofertę pakietów kwotowych!")}</span></div>
                        <Button onClick={selectMoneyVouchers} className="bg-white text-secondary p-2 rounded">{t("Vouchery kwotowe")}</Button>
                        </div>}
                    </React.Fragment>
                    )
            }
        </React.Fragment>
    );
};

export default Packages;


import React, {useContext, useState, useEffect} from "react"
import { Variant } from "../../models/Product.model"
import classNames from "classnames"
import { TimeSlot } from "../../models/TimeSlot.model"
import DateTimeSelector from "../CalendarSelector/DateTimeSelector"
import { CalendarDay, CalendarTime } from "../../models/CalendarDay.model"
import { CartContext, Types } from "../../contexts/reducers"
import { getCalendar } from "../../utils/Api.service"
import LoadingScreen from "../LoadingScreen/LoadingScreen"
import moment from "moment"
import ReactGA from 'react-ga4';
import {useTranslation} from "react-i18next";
import { tennatId } from "../../utils/RealClient.service"
import { usePostHog } from "posthog-js/react"
import { useFeatureValue } from "@growthbook/growthbook-react"

type RangeTimeSelectorProps = {
    parentVariant: Variant,
    selectedVariant: Variant,
    selectedQuantity: number,
    calendars: string[],
    onUpdateSelectedQuantity: (newQuantity: number) => void,
    onBack: () => void,
    onForward: () => void,
}


const RangeTimeSelector = (props: RangeTimeSelectorProps) => {
    const {t} = useTranslation()
    const [daysLoaded, setDaysLoaded] = useState<number>(0)
    const [viewDate, setViewDate] = useState<Date>(new Date())
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot>()
    const [calendarDays, setCalendarDays] = useState<CalendarDay[]>([])
    const {state, dispatch} = useContext(CartContext);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    const posthog = usePostHog()
    const [preSelectedSlot, setPreSelectedSlot] = useState<{date: Date, time: string}|null>(null)
    const daysToLoad = useFeatureValue('shop-packages-time-selector-available-days', 91)
    const getTenantDelay = (): string => {
        const hourTenants = [
            '00000000-0000-0000-0000-000000000000',
            '00000000-0000-0000-0000-000000000001',
            'c0110000-454f-4915-8bd5-1bcb26da61de',
            '96fbf968-be86-42dc-b826-de6f5e358ac5',
            '40bea4c2-27aa-45c2-9cc6-6a715df237cd',
            '3a41041e-ec98-4bb2-8676-2a9a988ca5e6',
        ]
        if (hourTenants.find(x => x === tennatId)) {
            return 'hour'
        } else {
            return 'day'
        }
    }

    useEffect(() => {
        const tommorow = new Date();
        const tenantDelay = getTenantDelay()
        if (tenantDelay === 'day') {
            tommorow.setDate(tommorow.getDate() + 1)
            tommorow.setHours(0, 0, 0, 0)
        } else if (tenantDelay === 'hour') {
            if (tommorow.getHours() >= 21) {
                tommorow.setDate(tommorow.getDate() + 1)
                tommorow.setHours(0,0,0,0)
            } else {
                tommorow.setHours(tommorow.getHours() + 2, 0,0,0)
            }
        }
        const next = new Date()

        next.setHours(0, 0, 0, 0)
        const loaded = Math.min(91, daysToLoad)
        next.setTime(tommorow.getTime() + loaded * 24 * 60 * 60 * 1000)
        setDaysLoaded(loaded)
        setIsDataLoading(true)
        loadCalendar(tommorow.toISOString(), next.toISOString(), true)
       
    }, [props.selectedQuantity, props.selectedVariant])

    const onVisibleDateRangeChange = (selectedDate: Date, singleMonth: boolean) => {
        if (daysToLoad <= 91) {
            //return
        }
        const toLoad =  Math.min(91, daysToLoad - daysLoaded)
        if (toLoad <= 0 ) {
            return
        }
        const visibleDays = singleMonth ? 29 : 58
        setViewDate(selectedDate)
        const endVisible = moment(selectedDate).add(visibleDays, 'd').endOf('month').startOf('d')
        if (moment().startOf('d').add(daysLoaded, 'd').isBefore(endVisible)) {
            setIsDataLoading(true)
            loadCalendar(moment().startOf('d').add(daysLoaded, 'd').toISOString(), moment().startOf('d').add(daysLoaded+toLoad, 'd').toISOString(), false)
            setDaysLoaded(daysLoaded+toLoad)
        }
    }

    const loadCalendar = (firstDate: string, lastDate: string, restart: boolean) => {
        getCalendar(props.selectedVariant.product.duration ?? 60, firstDate, lastDate, state.cart.id, props.selectedVariant.product.sku, props.selectedQuantity, 60).then(days => {
            const groupedDays = calculateGroupedAvailabilities(days)
            const newDays = groupedDays.map(x => ({date: x.date, times: x.times.filter(time => time.availableCount ?? 0 >= props.selectedQuantity)}))
            if (restart) {
                setCalendarDays(newDays)
            } else {
                const arr = [...calendarDays]
                arr.push(...newDays)
                setCalendarDays(arr)
            }
            let selectedDate = ''
            if (state.booking?.type === 'timeslot') {
                selectedDate = state.booking?.day.split('-').reverse().join('-')
            }
            const availableTime = days.find(day => state.booking?.type === 'timeslot' && day.date === selectedDate)
                ?.times.flatMap(x => x)
                ?.find(time => state.booking?.type === 'timeslot' && time.time === state.booking?.time)

            if (state.booking && !(availableTime && state.booking?.type === 'timeslot') && state.booking?.type !== 'voucher') {
                dispatch({type: Types.RemoveTimeslot, payload: null})
            }
            setIsDataLoading(false)
        }).catch(e => setIsDataLoading(false))
    }

    //@ts-ignore
    const  groupBy = (xs: Array<object>, key: string) => {
        return xs.reduce(function(rv, x) {
            //@ts-ignore
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      //@ts-ignore
    const calculateGroupedAvailabilities = (res):CalendarDay[] => {
        return res?.map((day: any) => {
            const groupedHours = groupBy(day.times, 'time');
            const grouppedHoursArray = Object.keys(groupedHours).map(key => ({
                time: key, 
                //@ts-ignore
                calendarId: groupedHours[key][0].calendarId,
                available: true,
                //@ts-ignore
                calendarIds: groupedHours[key].map((cal: CalendarTime) => cal.calendarId),
                //@ts-ignore
                availableCount: groupedHours[key].length
            }))
            

            return {date: day.date, times: grouppedHoursArray}
        
        })
    }
    const onTimeSelected = (date: string, time: string, calendarId: string) => {
        if (selectedTimeSlot && selectedTimeSlot.type === 'timeslot' && selectedTimeSlot.day === date && selectedTimeSlot.time === time) {
            return;
        }
        dispatch({type: Types.UpdateCartItemTimeSlot, payload: {index: 0, timeslot: {type: 'timeslot', day: moment(date,'DD-MM-YYYY').format('YYYY-MM-DD'), time: time, duration: props.selectedVariant.product.duration ?? 60, calendarIds: [calendarId]}}})
        setSelectedTimeSlot({
            type: 'timeslot', 
            day: date, 
            time: time, 
            duration: state.cart.items[0].duration,
             calendarIds: [calendarId]
            })
            ReactGA._gtag("event", "time_slot_selected", {
                date: date,
                time: time 
            })
            posthog.capture('merch range time_slot_selected')
    }
    useEffect(() => {
        ReactGA._gtag("event", "open_calendar")
        posthog.capture('merch range time_selector_opened')

        if (state.cart.items.length > 0 && state.cart.items[0].timeslot) {
            setSelectedTimeSlot({
                type: 'timeslot', 
                day: moment(state.cart.items[0].timeslot.date,'YYYY-MM-DD').format('DD-MM-YYYY'), 
                time: state.cart.items[0].timeslot.time, 
                duration: state.cart.items[0].duration,
                //@ts-ignore
                calendarIds: state.cart.items[0].timeslot.calendarIds
            })
        } else {
            const preDefinedSlot = (new URLSearchParams(window.location.search)).get('time')
            if (!preDefinedSlot) {
                return
            }
            const timeSlot = moment(parseInt(preDefinedSlot))
            const selectedDate=timeSlot.toDate()
            const selectedTime = timeSlot.format("HH:mm")
            setPreSelectedSlot({date:selectedDate, time:selectedTime})
        }

    
        
    }, [])
    return (<React.Fragment>

            <div className="lg:flex relative">
                <div className={"w-full  "}>
                    <div className={"bg-white lg:mr-4 pt-4 md:pt-6 pb-8 lg:ml-4"}>
                    <div className={"relative transition-all duration-300 ease-in-out opacity-100"}>
                    <div className={classNames({
                                        "w-full lg:mr-4  h-full transition duration-200 ease-linear absolute": true,
                                        "opacity-100 z-30i ": isDataLoading,
                                        "opacity-0 hidden": !isDataLoading,
                })}>
                    <LoadingScreen message={t("Ładowanie dostępnych terminów")} link={{link: "", text: ""}}/>
            </div>
                        <DateTimeSelector viewDate={viewDate} onMonthUpdated={onVisibleDateRangeChange} preSelectedSlot={preSelectedSlot} skipHalfHours={true} forceSingle={false} onDateTimeSelected={onTimeSelected} selectedTimeSlot={selectedTimeSlot} calendarDays={calendarDays}/>
                    </div>
                      
                    </div>
                </div>
            </div>
    </React.Fragment>)
}

export default RangeTimeSelector
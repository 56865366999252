import {Dialog} from "primereact/dialog";
import React from "react";
import {SellableProduct} from "../../models/Product.model";
import {getImageUrl} from "../../utils/Api.service";
import './WeaponDialog.scss';
import LoadableImage from "../Image/LoadableImage";
import {useTranslation} from "react-i18next";

type WeaponDialogProps = {
    item: SellableProduct,
    isDialogOpen: boolean,
    onHide: () => void
}

export const WeaponDialog = (props: WeaponDialogProps) => {
    const {t} = useTranslation()
    return (
    <Dialog dismissableMask={true} visible={props.isDialogOpen} className="w-600px max-w-full bg-white relative weapon-info-dialog pb-4" onHide={props.onHide}>
        <div className={"w-full inline-block p-4"}>
            <LoadableImage
                classes={"w-full md-4 object-contain max-h-80"}
                thumbnailData={"data:" + props.item.thumbnailType + ";base64, " + props.item.thumbnail}
                imageSrc={getImageUrl(props.item.sku, 'large')}
                height={320}
                previewAvailable={false}
            />
             <div className="text-3xl font-bold pb-4 border-b pl-4">
                {props.item.name}<br/>{props.item.customAttributes && props.item.customAttributes.find( x=> x.name === 'kaliber') && props.item.customAttributes.find(x => x.name === 'kaliber')?.value &&<span className="text-base">{t('Kaliber')}: {props.item.customAttributes.find(x => x.name === 'kaliber')?.value}</span> }
                </div>
             
        <p className="pt-4 px-4 text-left text-justify" dangerouslySetInnerHTML={{__html: props.item.description}}/>
        </div>
    </Dialog>
    )
}

export default WeaponDialog;

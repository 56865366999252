import axios from 'axios'
import detectLanguage from './LanguageDetector';
import { LanguagesFullCodes } from './Languages';

const convert = require('color-convert');
export const baseURL = process.env.REACT_APP_API_BASEURL + '/api/v1/shop/'
export const tennatId = (new URLSearchParams(window.location.search)).get('tenantId') ?? '00000000-0000-0000-0000-000000000000'

export const authHeader = (new URLSearchParams(window.location.search)).get('auth')
var tenantLanguage = LanguagesFullCodes[detectLanguage()]
//@ts-ignore
try { tenantLanguage = localStorage.getItem('lang') ??  LanguagesFullCodes[detectLanguage()]} catch(e) {tenantLanguage =  LanguagesFullCodes[detectLanguage()]}

function is_color_light(color: string) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 137;
}


export const color = ((new URLSearchParams(window.location.search)).get('color') ?? '7aa329')
const hsl = convert.hex.hsl(color);
const rgb = convert.hex.rgb(color)
document.documentElement.style.setProperty('--accent-color', '#' + color);
document.documentElement.style.setProperty('--accent-contrast-color',is_color_light(color) ? '#2c3034' : '#ffffff')
document.documentElement.style.setProperty('--accent-color-darker', 'hsl(' + hsl[0] + ', ' + hsl[1] + '%, ' + (hsl[2] - 10) + '%)');
document.documentElement.style.setProperty('--accent-color-darker2', 'hsl(' + hsl[0] + ', ' + hsl[1] + '%, ' + (hsl[2] - 2) + '%)');

document.documentElement.style.setProperty('--accent-color-dimmed', 'rgba(' + rgb[0] + ', ' + rgb[1] + ', ' + hsl[2] + ', 0.1)');
document.documentElement.style.setProperty('--accent-color-lighter', 'rgba(' + rgb[0] + ', ' + rgb[1] + ', ' + hsl[2] + ', 0.85)');

const instance = axios.create({
    baseURL: baseURL,
    timeout: 25000,
    headers: {
        'X-Tenant-ID': tennatId,
        'X-Language': tenantLanguage,
        ...(authHeader && { Authorization: authHeader })
    }
})
export const client = instance


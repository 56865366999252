import {TailSpin} from "react-loader-spinner";
import {color} from "../../utils/RealClient.service";
import React from "react";
import {useTranslation} from "react-i18next";

type LoadingScreenProps = {
    message: string,
    link: {link: string, text: string}
}
const LoadingScreen = (props: LoadingScreenProps) => {
    const {t} = useTranslation()
    return (
        <div id="data-loading" className={"w-full h-full absolute z-10 bg-white text-secondary"}>
            <div className={"top-20vh absolute w-full"} >
                <div className={"mx-auto"} style={{width: '80px'}}>
                <TailSpin  color={"#" + color} height={80} width={80}/>
                  </div>
                <div className={"w-full text-center text-lg mt-4"}>{props.message}</div>
                {props.link.link !== "" && <div className={"w-full text-center text-lg mt-4"}>
                    {props.link.text} <a href={props.link.link} target="_top"><button className="text-lg bg-secondary  border-2 border-secondary text-white shadow-xl rounded
                     w-72 hover:bg-white hover:text-secondary flex justify-center text font-bold mx-auto mb-4 max-w-full py-2"
                >{t('Przejdź do płatności')}</button></a> </div>}

            </div>
        </div>
    )
}
export default LoadingScreen
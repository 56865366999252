enum SupportedLanguages {
    PL = 'PL',
    EN = 'EN',
    DE = 'DE'
}
enum SupportedLanguagesNames {
    PL = 'Polski',
    EN = 'English',
    DE = 'Deutch'
    }
enum SupportedLanguagesFlags {
    PL = 'PL',
    EN = 'GB',
    DE = 'DE'
}
enum LanguagesFullCodes {
    'PL' = "pl_PL",
    'EN' = "en_GB",
    'DE' = "de_DE"
}
export { SupportedLanguages, SupportedLanguagesNames, SupportedLanguagesFlags, LanguagesFullCodes}

import {CartItem} from "../../models/Cart.model";
import {getCartItemLabel} from "../../utils/UnitUtils";
import moment from "moment";
import {getFormattedPrice} from "../../utils/PriceUtils";
import {BsPlus, BsTrash} from "react-icons/bs";
import React, { useContext, useState } from "react";
import { FaMinus, FaPlus} from "react-icons/fa";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import { CartContext } from "../../contexts/reducers";
import { CurrencyContext } from "../../contexts/CurrencyProvider";
import { FiMinus, FiPlus } from "react-icons/fi";

type CartItemElementProps = {
    cartItem: CartItem,
    isSummaryPage: boolean,
    onDeletePackage: () => void,
    onDeleteUpsell: (upsellElementIndex: number) => void
    onUpdateQuantity: (change: number) => void
}
const CartItemElement = (
   props: CartItemElementProps,
) => {
    const {t} = useTranslation()
    const {currency} = useContext(CurrencyContext)
    const {state} = useContext(CartContext)
    return (
        <div className={classNames({"mb-4 w-full   transition-all duration-100 border-b border-gray-200": true, })}>
            <div className="w-full flex pb-4 rounded-lg">
                <div className={"flex justify-between w-full "}>
                    <div>
                        <span className="font-medium text-gray-900 text-lg">
                            {props.cartItem.name}
                        </span>
                        {getCartItemLabel(props.cartItem, state.language).map((label, index) => {return(
                            <div key={index} className="mt-1 text-sm text-gray-500">
                                {label}
                            </div>)})}
                        {props.cartItem.timeslot &&
                        <div className={"text-left text-sm"}>
                            {moment(props.cartItem.timeslot.date, 'YYYY-MM-DD').format('DD MMMM YYYY')}
                            <br/>{props.cartItem.timeslot.time}
                        </div>
                        }
                    </div>
                    <div className={"text-right"} style={{minWidth: '85px'}}>
                        <span className="text-right text-lg font-bold text-dark">
                            {getFormattedPrice(props.cartItem.price, true, currency)}
                        </span>
                        {!props.isSummaryPage ?
                            <div className="justify-end  text-dark flex text-base">
                                <button onClick={() =>{props.onUpdateQuantity(-1)}}
                                        className="text-secondary hover:bg-zinc-200  cursor-pointer rounded p-1  hover:bg-white ">
                                    {props.cartItem.quantity > 1 ? <FiMinus size="18"/> :  <BsTrash size="18"/>}
                                </button>
                            
                            
                                <div className="mx-2">{props.cartItem.quantity}</div>
                                
                                {(props.cartItem.type === 'BUNDLE' || props.cartItem.type === 'EXTENSION')?
                                <button onClick={() => {props.onUpdateQuantity(1);}}
                                        className="font-bold text-secondary hover:bg-zinc-200  cursor-pointer rounded p-1 hover:bg-white">
                                    <FiPlus size="18"/>
                                </button>
                                :
                                <div 
                                    className="cursor-not-allowed text-grey border-2 p-1 rounded">
                                <FiPlus size="18"/>
                                </div>}                                
                            </div> :
                            <div className="justify-end  text-dark flex">
                                {props.cartItem.quantity > 1 && <div className="mx-1">x {props.cartItem.quantity}</div>}
                                </div>
                        }
                    </div>
                </div>
            </div>
            {(props.cartItem.upsell?.length ?? 0) > 0 &&
            <div className="bg-secondary-dimmed border-b-2 border-secondary">
                <div className="">
                    {(props.cartItem.children?.length ?? 0)> 0 &&<span className="text-grey-500 text-sm">{t('Dodatkowo')}</span>}
                    {props.cartItem.upsell?.map((el, index) => {
                        return (
                            <div className="w-full flex justify-end flex-wrap py-2" key={index}>
                                <div className="mr-auto text-left font-medium text-gray-900 text-base">
                                    {el.name} {getCartItemLabel(el, state.language).map((label, index2) => {return(
                                        <div key={index2} className="text-sm text-gray-500">
                                            {label}
                                        </div>)})}
                                </div>
                                <div className={"text-right"} style={{minWidth: '85px'}}>
                                    <span className="text-right text-lg font-bold">
                                        + {getFormattedPrice(el.price * el.quantity, true, currency)}
                                    </span>
                                    
                                {!props.isSummaryPage &&
                                <div className="justify-end  text-dark flex">
                                    <button onClick={() => props.onDeleteUpsell(index)}
                                        className="text-secondary hover:text-secondary  cursor-pointer rounded border-2 p-1 hover:bg-white border-secondary">
                                        <BsTrash size="12"/>
                                    </button>
                                  
                                    <div className="mx-2">{el.quantity * props.cartItem.quantity}</div>
                                    <div 
                                        className="text-white cursor-not-allowed border-grey text-grey border-2 p-1 rounded">
                                    <FaPlus size="12"/>
                                    </div>
                               
                                
                                </div>
                                }
                                                                </div>

                            </div>
                        )
                    })}
                </div>
            </div>
            }
        </div>
    )
}
export default CartItemElement;
import React, {useContext, useEffect, useState} from 'react';
import {TimeSlot} from "../../models/TimeSlot.model";
import {Dialog} from "primereact/dialog";
import {FiChevronDown, FiChevronLeft, FiShoppingCart} from "react-icons/fi";

import classNames from "classnames";
import "./Cart.scss";
import {CartError, CartItem} from "../../models/Cart.model";
import {DiscountResult} from "../../models/Discount.model";
import {InputText} from "primereact/inputtext";
import ReactGA from "react-ga4";
import {CartContext, Types} from "../../contexts/reducers";
import {updateCart} from "../../utils/Api.service";
import {getFormattedPrice} from "../../utils/PriceUtils";
// @ts-ignore
import {getCartItemLabel} from "../../utils/UnitUtils";
import moment from "moment";

import CartItemElement from "./CartItemElement";
import useWindowResizeValue from '../../utils/useWindowResizeValue';
import {useTranslation} from "react-i18next";
import { BsTrash } from 'react-icons/bs';
import { CurrencyContext } from '../../contexts/CurrencyProvider';
import { usePostHog } from 'posthog-js/react';

type CartProps = {
    onGotoNextStep: () => void
    activeIndex: number
    timeslot?: TimeSlot,
    onCartOpen: (opened: boolean) => void,
    onUpdateStepIndex: (index: number) => void,
}

type CartContetProps = CartProps & {
    onWindowDismiss: () => void,
    vouchers: DiscountResult[],
    hideTitle?: boolean,
}
const Cart = (props: CartProps) => {
    const {state, dispatch} = useContext(CartContext);
    const {currency} = useContext(CurrencyContext)
    const posthog = usePostHog()
    const {t} = useTranslation()
    const [isMobileCartOpen, setIsMobileCartOpen] = useState(false)
    const [appliedDiscount, setAppliedDiscount] = useState<DiscountResult>()
    const [couponViewOpened, setCouponViewOpened] = useState<boolean>(false)
    const [discountCode, setDiscountCode] = useState<string>('')
    const [discountMessage, setDiscountMessage] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [successMessage, setSuccessMessage] = useState<string>('')

    const [isDiscountDialogOpened, setIsDiscountDialogOpened] = useState<boolean>(false)
    const [nextButtonMessage, setNextButtonMessage] = useState('')
    const [textButtonNext, setTextButtonNext] = useState<string>(t('Kontynuuj'))
    const windowWidth = useWindowResizeValue()
    useEffect(()=>{
        setIsMobileCartOpen(false)
    }, [windowWidth])

    useEffect(() => {
         const [percentDiscountVoucher] = state.cart.discounts
            setAppliedDiscount(percentDiscountVoucher)
            setDiscountCode(percentDiscountVoucher?.code ?? '')

    }, [state.cart.discounts])

    useEffect(() => {
        if ((state.cart.items?.length ?? 0) + (state.cart.discounts?.filter(x => x.type === 'BUNDLE_VOUCHER')?.length ?? 0) === 0) {
            setIsMobileCartOpen(false)
        }

    }, [state.cart.discounts, state.cart.items])

    useEffect(() => {
        props.onCartOpen(isMobileCartOpen)
    }, [isMobileCartOpen])

    useEffect(() => {
        if (props.activeIndex === 0) {
            setNextButtonMessage(t('Wybierz produkt'))
        } else if (props.activeIndex === 1) {
            setNextButtonMessage(t('Podaj szczegóły'))
        } else {
            setNextButtonMessage(t("Zamów i zapłać"))
        }
    }, [props.activeIndex, props.timeslot])
    const isSummaryPage = () => {
        return props.activeIndex === 2
    }
    const canGoNextBeActive = () => {
        return (state.cart.items?.length ?? 0) + (state.cart.discounts?.filter(x => x.type === "BUNDLE_VOUCHER").length ?? 0) > 0 && (props.activeIndex === 0 || (props.timeslot && (props.timeslot.type === 'timeslot' || props.timeslot.owner?.length > 0)))
    }
    // const swipeHandlers = useSwipeable({
    //     onSwipedUp: (eventData) => setIsMobileCartOpen(true),
    //     onSwipedDown: (eventData) => setIsMobileCartOpen(false),
    //     preventDefaultTouchmoveEvent: true
    // });
    const processCouponCode = () => {
        setErrorMessage('')
        if (appliedDiscount) {
            removeDiscount(appliedDiscount)
            return
        }
        const discounts = state.cart.discounts ?? []
        discounts.unshift({code: discountCode})
        const newCart = {
            ...state.cart,
            discounts: discounts
        }
        updateCart(newCart).then((result) => {

            if (result.hasOwnProperty("id")) {
                dispatch({type: Types.UpdateCart, payload: result})
            }
            const appliedDiscount = result.discounts?.find(x => x.code === discountCode)
            const appliedDiscountDesc = appliedDiscount?.description ?? ''
            setDiscountMessage(appliedDiscountDesc)
            if (appliedDiscount) {
                ReactGA._gtag("event", "selected_promotion", {
                    creative_name: appliedDiscount.type,
                    promotion_id: appliedDiscount.code,
                    promotion_name: appliedDiscount.name,
                })
                setSuccessMessage(t("Pomyślnie zaaplikowano kod"))
                setTimeout(() => {
                    setSuccessMessage('')
                }, 4500)

            }
        }).catch((errorResult: CartError) => {
            setErrorMessage(errorResult.errors.discounts.find(x => x.key === discountCode)?.message ?? '')
            setTimeout(() => {
                setErrorMessage('')
            }, 4500)
            dispatch({type: Types.ReplaceCart, payload: errorResult.fixedCart})
        })

    }
    const removeDiscount = (discount: DiscountResult) => {
        const discounts = state.cart.discounts?.filter(x => x.code !== discount.code) ?? []
        const newCart = {
            ...state.cart,
            discounts: discounts
        }
        updateCart(newCart).then((result) => {
            dispatch({type: Types.UpdateCart, payload: result})
        })
    }

    useEffect(() => {
        if (props.activeIndex === 0 && (!state.booking || state.booking?.type === 'timeslot') && state.calendarActive) 
            setTextButtonNext(t("Kontynuuj"))
            if (props.activeIndex === 0 && state.booking?.type === "voucher") {
                setTextButtonNext( t("Kontynuuj"))
            }
            if (props.activeIndex === 0 && !state.calendarActive) {
                setTextButtonNext(t("Kontynuuj"))
            }
            if (props.activeIndex === 1) {
                setTextButtonNext(t("Kontynuuj"))
            }
    }, [props.activeIndex, state.calendarActive, state.booking])
    return (<React.Fragment>
            <Dialog dismissableMask={false} header={t("Dodano kupon do zamówienia")} visible={isDiscountDialogOpened}
                    className="min-h-72 bg-white p-4 relative" onHide={() => setIsDiscountDialogOpened(false)}>
                <p className="tp-6 text-left text-justify" dangerouslySetInnerHTML={{__html: discountMessage}}/>
                <div className={"mt-auto absolute bottom-0 left-0 w-full"}>
                    <button className="mx-auto mb-8 bg-secondary text-white p-button-raised rounded
                    w-72 hover:bg-secondary-darker flex justify-center text font-bold mb-2 max-w-full border-none mt-auto"
                            onClick={() => setIsDiscountDialogOpened(false)}>{t('Ok')}</button>
                </div>
            </Dialog>
            <div className={classNames({"hidden lg:block  bg-grey-50  rounded-lg ": true})}>
                <div className="mt-16  px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8">
                    <CartContent {...props} vouchers={state.cart.discounts?.filter(x => x.type === "BUNDLE_VOUCHER")} onWindowDismiss={() => {}}/>
                    {appliedDiscount && appliedDiscount.type === 'PERCENT_COUPON' &&
                    <div className="flex mt-8">
                        <div className="w-1/2">
                            <span className={"font-bold text-green"}>{t('Rabat')} {appliedDiscount.value}%</span>
                        </div>
                        <div className="w-1/2">
                            {state.cart.basePrice - state.cart.price > 0 && <div
                                className="text-xl font-bold text-right text-green"> - {getFormattedPrice(state.cart.basePrice - state.cart.price, true, currency)}</div>}
                        </div>
                    </div>
                    }
                    {appliedDiscount && (appliedDiscount.type === 'MONEY_VOUCHER' || appliedDiscount.type === 'MONEY_COUPON') &&
                    <div className="flex mt-8">
                        <div className="w-1/2">
                            <span className={"font-bold text-green"}>{t('Rabat')} {getFormattedPrice(appliedDiscount.value ?? 0, true, currency)}</span>
                        </div>
                        <div className="w-1/2">
                            {state.cart.basePrice - state.cart.price > 0 && <div
                                className="text-xl font-bold text-right text-green"> - {getFormattedPrice(state.cart.basePrice - state.cart.price, true, currency)}</div>}
                        </div>
                    </div>
                    }
                                    {state.cart.items.length == 0 ? <div className="text-md text-gray-700 my-12">{t('Twój koszyk jest pusty')}</div> :  

                    <div className="flex">
                        <div className="w-1/2">

                            <span className={"text-base font-medium text-gray-900"}>{t('Łączna kwota')}</span><span className={"text-xs text-grey-400"}> {t('Z VAT')}</span>

                        </div>
                        <div className="w-1/2">
                            <div className="text-xl font-bold text-right">{getFormattedPrice(state.cart.price, true, currency)}</div>
                        </div>
                    </div>
                    }
               
                {!isSummaryPage() && (
                    canGoNextBeActive() ? <button
                            className={classNames({
                                "shadow mt-4 font-bold bg-secondary w-full font-3xl rounded py-2 text-white hover:bg-secondary-lighter": true,
                            })}

                            onClick={() => {
                                props.onGotoNextStep();
                                posthog.capture('merch go_next clicked')
                                setIsMobileCartOpen(false);
                            }}>
                            {t('Kontynuuj')}
                        </button> :
                        <div className="inactive-go-next-button shadow-lg bg-white text-dark font-bold w-full border-secondary border-2 py-2 rounded"><span>{nextButtonMessage}</span></div>
                )
                }
                <div className={"mt-4"}>
                    <span className={"cursor-pointer  text-secondary"} onClick={() => setCouponViewOpened(!couponViewOpened)}>{t('Mam kod rabatowy')}
                        <FiChevronDown className={classNames(
                            {
                                "inline-block transform duration-150 ease-in-out mb-1": true,
                                "rotate-180": couponViewOpened
                            })}/>
                    </span>
                    {
                        true &&
                        <div className={classNames({"mt-2  transition-all duration-100 delay-0": true, "h-32": couponViewOpened, "h-0": !couponViewOpened})}>
                            <div className={classNames({"transition-all duration-100 ": true, "opacity-100 delay-150": couponViewOpened, "opacity-0 delay-400":!couponViewOpened})}>

                            
                            <div className="flex flex-wrap">
                                <InputText  className={"block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm p-inputtext p-component block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"}
                                           value={discountCode} onChange={e =>
                                    // @ts-ignore
                                    setDiscountCode(e.target.value)}
                                           disabled={appliedDiscount !== undefined}/>
                                    <button className="mt-3 rounded-md border border-transparent bg-secondary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-darker focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last  sm:w-auto "

                                        onClick={processCouponCode}
                                >{appliedDiscount ? t("Usuń") : t("Dodaj")}</button>
                            </div>
                                   
                            {
                                !successMessage && errorMessage && <span className={"text-red text-xs font-bold"}> {t(errorMessage)} </span>
                            }
                             {
                                successMessage && <span className={"text-green text-xs font-bold"}> {successMessage} </span>
                            }
                            </div>
                        </div>
                    }
                </div>
                </div>
            </div>
            {(state.cart.items.length > 0 || (state.cart.discounts?.length ?? 0) > 0) &&
            <div className={classNames({
                "block lg:hidden": true,
                "hidden": isSummaryPage()
            })}
            >

                <div className={classNames({"mobile-full-cart": true, "opened": isMobileCartOpen})}>
                    <div className="cart-items items-center">
                        <div className={"mt-4 mx-4 flex relative"}>
                            <div className="absolute cursor-pointer p-2 hover:bg-grey-100 rounded-lg"
                                 onClick={() => setIsMobileCartOpen(!isMobileCartOpen)}>
                                <FiChevronDown/>
                            </div>
                            <div className={"mx-auto"}><span className="text-lg font-bold text-gray-900">{t('Koszyk')}</span></div>
                        </div>
                        <div className="mx-4 pb-24 cart-content mt-8">
                            <CartContent hideTitle={true} {...props} vouchers={state.cart.discounts?.filter(x => x.type === "BUNDLE_VOUCHER") } onWindowDismiss={() => {}}/>
                            {appliedDiscount &&
                            <div className="flex mt-8">
                                <div className="w-1/2">
                                    <span className={"font-bold text-green"}>{t('Rabat')} {appliedDiscount.value}%</span>
                                </div>
                                <div className="w-1/2">
                                    {(state.cart.basePrice - state.cart.price) > 0 && <div
                                        className="text-xl font-bold text-right text-green"> - {getFormattedPrice(state.cart.basePrice - state.cart.price, true, currency)}</div>}
                                </div>
                            </div>
                            }
                            <div className="flex">
                                <div className="w-1/2">

                                    <span className={"font-bold"}>{t('Suma')}</span><span className={"text-xs"}> {t('Z VAT')}</span>

                                </div>
                                <div className="w-1/2">
                                    <div className="text-xl font-bold text-right">{getFormattedPrice(state.cart.price, true, currency)}</div>
                                </div>
                            </div>
                            {!isSummaryPage()  && (
                                canGoNextBeActive() ? <button
                                        className={classNames({
                                            "shadow mt-4 font-bold rounded bg-white text-secondary w-full font-3xl py-2 border-2 border-secondary rounded hover:bg-secondary hover:text-white": true,
                                        })}

                                        onClick={() => {
                                            props.onGotoNextStep();
                                            posthog.capture('merch go_next clicked')
                                            setIsMobileCartOpen(false);
                                        }}>
                                        {t("Kontynuuj")}
                                    </button> :
                                    <div className="inactive-go-next-button shadow-lg bg-white text-dark font-bold w-full border-secondary border-2 py-2 rounded"><span>{nextButtonMessage}</span></div>
                            )
                            }
                            
                            <div className={classNames({"my-4": true})}>
                                <span className={"cursor-pointer text-sm text-secondary"} onClick={() => setCouponViewOpened(!couponViewOpened)}>{t('Mam kod rabatowy')}
                                    <FiChevronDown className={classNames(
                                        {
                                            "inline-block transform duration-150 ease-in-out mb-1": true,
                                            "rotate-180": couponViewOpened
                                        })}/>
                                </span>
                                {
                                    couponViewOpened &&
                                    <div className={"mt-2"}>

                                        <span className={"text-red"}>  </span>
                                        <div>
                                            <InputText className={"text-center p-inputtext p-component inline-block mt-1"}
                                                       value={discountCode} onChange={e =>
                                                // @ts-ignore
                                                setDiscountCode(e.target.value)}
                                                       disabled={appliedDiscount !== undefined}/>
                                            <button className="p-2 -block bg-secondary text-white shadow rounded
                      hover:bg-secondary-lighter justify-center text font-bold mx-auto max-w-full border-2 border-secondary mt-1 ml-2"

                                                    onClick={processCouponCode}
                                            >{appliedDiscount ? t("Usuńf") : t("Dodaj")}</button>
                                        </div>
                                        {
                                            !successMessage && errorMessage && <span className={"text-red text-xs font-bold"}> {t(errorMessage)} </span>
                                        }
                                        {
                                            successMessage && <span className={"text-green text-xs font-bold"}> {successMessage} </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"w-full fixed left-0 bottom-0 border border-secondary rounded-full"}>
                    <div onClick={() => setIsMobileCartOpen(!isMobileCartOpen)}
                         className={"z-50 rounded-full absolute bg-secondary shadow-lg hover:bg-secondary-darker hover:border-secondary-darker cursor-pointer"}
                         style={{bottom: '20px', left: 'calc(50% - 30px)', height: '60px', width: '60px'}}>
                        <span
                            className={"bg-white rounded-lg text-primary absolute text-center text-sm cursor-pointer"}
                            style={{
                                height: '20px',
                                width: '20px',
                                left: '21px',
                                top: '5px'
                            }}>{(state.cart.items?.reduce((acc, e) => e.quantity + acc, 0) ?? 0) + (state.cart.discounts?.filter(x => x.type === 'BUNDLE_VOUCHER')?.length ?? 0)}</span>
                        <FiShoppingCart className={"cursor-pointer text-contrast"} size={25}
                                        style={{marginLeft: '16px', marginTop: '23px'}}/>
                    </div>
                    <div className={"inline-block bg-grey-100 rounded-lg absolute left-0 bottom-0 shadow-lg"} style={{
                        height: '65px',
                        width: 'calc(50% - 37.5px)',
                        borderBottomRightRadius: 0,
                        boxShadow: 'rgb(255 255 255 / 0.46) 1px -6px 16px 0px'
                    }}>
                        <div
                            className={"text-center text-xl mt-5 font-bold"}>{

                                state.cart.price > 0 && getFormattedPrice(state.cart.price, true, currency) }
                            {state.cart.price === 0 && "Vouchery: " + state.cart.discounts.filter(x => x.type === 'BUNDLE_VOUCHER').length}
                        </div>
                    </div>
                    <div className={"inline-block absolute"} style={{
                        width: '75px',
                        left: 'calc(50% - 37.5px)',
                        bottom: 0,
                        height: '50px',
                        backgroundImage: "url('/cart-mobile.svg')"
                    }}/>
                    <div className={"inline-block bg-grey-100 rounded-lg absolute right-0 bottom-0 shadow-lg"} style={{
                        height: '60px',
                        width: 'calc(50% - 37.5px)',
                        borderBottomLeftRadius: 0,
                        boxShadow: 'rgb(255 255 255 / 0.46) 1px -6px 16px 0px'
                    }}>
                        <div className="mt-2 mx-1">
                            {canGoNextBeActive() ? <button
                                    className={classNames({
                                        "leading-6 shadow-lg bg-white text-dark font-bold w-full rounded border-secondary border-2 py-2 hover:bg-secondary hover:border-secondary hover:text-white": true,
                                        "bg-grey cursor-default ": props.activeIndex === 0 && (state.cart.items?.length ?? 0) + state.cart.discounts?.filter(x => x.type === "BUNDLE_VOUCHER").length === 0
                                    })}
                                    onClick={() => {
                                        props.onGotoNextStep();
                                        posthog.capture('merch go_next clicked')
                                        setIsMobileCartOpen(false);
                                    }}>
                                        {textButtonNext}
                                </button>
                                :
                                <div
                                    className="inactive-go-next-button shadow-lg bg-white text-dark font-bold w-full border-secondary border-2 py-2 rounded ">{nextButtonMessage}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
        </React.Fragment>
    );
}

const CartContent = (props: CartContetProps) => {
    const {state, dispatch} = useContext(CartContext);
    const {t} = useTranslation()
    const {currency} = useContext(CurrencyContext)

    const deletePackage = (deletedItem: CartItem) => {
        dispatch({
            type: Types.Delete,
            payload: deletedItem
        })
        ReactGA._gtag("event", "remove_from_cart", {
            currency: currency,
            value: (deletedItem.price / 100.00),
            "items": [{
                "item_id": deletedItem.sku,
                "item_name": deletedItem.name,
                "currency": currency,
                "price": (deletedItem.price / 100.00),
                "quantity": 1
            }]
        })
    }

    const updateItemQty = (updatedItem: CartItem, index: number, newQty: number) => {
        if (newQty <= 0) {
            deletePackage(updatedItem)
        } else {
            dispatch({
                type: Types.Update,
                payload: {item:{...updatedItem, quantity: newQty}, index: index}
            })
            if (newQty > updatedItem.quantity) {
            ReactGA._gtag("event", "add_to_cart", {
                currency: currency,
                value: (updatedItem.price / 100.00),
                "items": [{
                    "item_id": updatedItem.sku,
                    "item_name": updatedItem.name,
                    "currency": currency,
                    "price": (updatedItem.price / 100.00),
                    "quantity": newQty
                }]
            })
            } else {
                ReactGA._gtag("event", "remove_from_cart", {
                    currency: currency,
                    value: (updatedItem.price / 100.00),
                    "items": [{
                        "item_id": updatedItem.sku,
                        "item_name": updatedItem.name,
                        "currency": currency,
                        "price": (updatedItem.price / 100.00),
                        "quantity": 1
                    }]
                })
            }
        }
    }

    const removeDiscount = (discount: DiscountResult) => {
        const discounts = state.cart.discounts?.filter(x => x.code !== discount.code) ?? []
        const newCart = {
            ...state.cart,
            discounts: discounts
        }
        updateCart(newCart).then((result) => {
            dispatch({type: Types.UpdateCart, payload: result})
        })
    }
    const deleteUpsellItem = (upsellItemIndex: number, itemIndex: number) => {
        const updatedCartItem = state.cart.items[itemIndex]
        updatedCartItem.upsell?.splice(upsellItemIndex, 1)
        if ((updatedCartItem.children?.length ?? 0) + (updatedCartItem.upsell?.length ?? 0) === 0) {
            deletePackage(state.cart.items[itemIndex])
        } else {
            dispatch({type: Types.Update, payload: {index: itemIndex, item: updatedCartItem}})
        }
    }
    const isSummaryPage = () => {
        return props.activeIndex === 2
    }
    return <React.Fragment>
        <div>
        {!props.hideTitle && <span className="text-lg font-bold text-gray-900 ">{t('Koszyk')}</span>}
            {((state.cart.items?.length ?? 0) + (state.cart.discounts?.filter(x => x.type === 'BUNDLE_VOUCHER')?.length ?? 0)) > 0 &&
            props.timeslot &&
            <div className={"mb-8   text-lg mt-4"}>
                {props.timeslot.type === 'voucher' ?
                <div className="mb-2 mt-4  text-lg border-b pb-4">
                    <span className=" pb-4 break-all">
                        {t('Voucher prezentowy')} {props.timeslot.owner.length > 0 && <span>{t('dla')}: <br/><span className="font-semibold">{props.timeslot.owner}</span></span>}
                                </span></div>
                    :
                    <div className="mb-4  text-lg border-b-2 border-secondary pb-4">
                        {(props.timeslot?.type === 'timeslot' && props.timeslot?.time) &&<>

                        <span className=" mb-2">
                            {t('Rezerwacja')}
                        </span>
                    
                        <span
                            className="float-right text-right font-bold">{moment(props.timeslot.day, 'DD-MM-yyyy').format('DD MMM yyyy')} {props.timeslot.time}</span>
                            
                        <div className={"clear-both"}/>
                        <span className="float-left mt-1 text-sm text-gray-500">
                                    {t('Długość')}
                                </span>
                        <span className="float-right mt-1 text-sm text-gray-500 font-bold">
                                    {props.timeslot.duration + " min"}
                                </span>
                        <div className={"clear-both mb-2"}/>
                        </>}
                    </div>
                }
                {state.cart.items.filter(x => x.type === 'BUNDLE' || x.type === 'EXTENSION').map((cartItem, cartIndex) => (
                    <CartItemElement  
                    key={cartIndex} 
                    cartItem={cartItem} 
                    isSummaryPage={props.activeIndex === 2}
                    onDeletePackage={() => deletePackage(cartItem)}
                    onDeleteUpsell={(upsellElementIndex) => deleteUpsellItem(upsellElementIndex, cartIndex)}
                    onUpdateQuantity={(number) => {updateItemQty(cartItem, cartIndex, cartItem.quantity + number)}}
                        />)
                )}
            </div>

            }
        </div>
        <div className="mt-6">
        
            {props.vouchers.map((itemVoucher) => {
                return (
                    <div className="mb-4 w-full pt-4 bg-white rounded-lg border border-primary" key={itemVoucher.code}>
                        <div className="mb-2  w-full flex">
                            <span className="pl-2 font-bold w-full"> {t('Voucher')}: {itemVoucher.code} </span>
                            {!isSummaryPage()  &&
                            <span className="mx-2 text-dark">
                                <button
                                    onClick={() => removeDiscount(itemVoucher)}
                                    className=" text-secondary cursor-pointer  rounded p-1 hover:bg-secondary hover:bg-zinc-200"
                                >
                                    <BsTrash size="18"/>
                                </button>
                            </span>
                            }
                        </div>
                        {itemVoucher.items?.map((kit, index) => {
                            return (
                                <div key={index}>
                                    <div className="bg-white w-full flex px-2 pb-2 rounded-lg">
                                        <span className="w-full text-left text-lg font-bold text-dark">
                                            {kit.quantity + "x " + kit.name}
                                        </span>
                                    </div>
                                    {(kit.upsell?.length ?? 0) > 0 &&
                                    <div className="bg-secondary-dimmed border-t-2 border-secondary">
                                        <div className="px-2">
                                            <span className="text-grey-500 text-sm">{t('Dodatki')}</span>
                                            {kit.upsell?.map((el, index) => {
                                                return (
                                                    <div className="w-full flex py-2" key={index}>
                                                        <span className="w-3/4 text-left text-lg font-bold text-dark">
                                                            {el.name} {el.quantity >= 1 &&
                                                        getCartItemLabel(el, state.language).map(label => (<div
                                                            className={"text-sm"}> {label}</div>))
                                                        }
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                )
            })}

            {state.cart.items.filter(x => !props.timeslot ||( x.type !== 'BUNDLE' && x.type !== 'EXTENSION')).map((cartItem, cartIndex) => {
                return (<CartItemElement 
                    key={cartIndex} 
                    cartItem={cartItem} 
                    isSummaryPage={props.activeIndex === 2}
                    onDeletePackage={() => deletePackage(cartItem)}
                    onDeleteUpsell={(upsellElementIndex) => deleteUpsellItem(upsellElementIndex, cartIndex)}
                    onUpdateQuantity={(number) => {updateItemQty(cartItem, cartIndex, cartItem.quantity + number)}}
                    />)
            })}
        </div>
    </React.Fragment>
}
export default Cart;

import { Variant } from "../../models/Product.model"

import { FiChevronDown, FiChevronLeft, FiShoppingCart } from "react-icons/fi"
import { InputText } from "primereact/inputtext"
import { getFormattedPrice } from "../../utils/PriceUtils"
import React, { useContext, useEffect, useState } from "react"
import { CartContext, Types } from "../../contexts/reducers"
import classNames from "classnames"
import { DiscountResult } from "../../models/Discount.model"
import moment from "moment"
import { updateCart } from "../../utils/Api.service"
import { CartError } from "../../models/Cart.model"
import ReactGA from 'react-ga4';
import {useTranslation} from "react-i18next";
import { useCurrency } from "../../contexts/CurrencyProvider"
import { usePostHog } from "posthog-js/react"
import { isBay } from "../../utils/RangeTypeDetector"

type RangeCartProps = {
    parentVariant?: Variant,
    onForward: () => void,
    isForwardButtonVisible: boolean,
    isFinalIndex: boolean,
    activeIndex: number,
    inputCoupon?: string
}
const RangeCart = (props: RangeCartProps) => {
    const {t} = useTranslation()
    const {currency} = useCurrency()
    const posthog = usePostHog()
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [couponViewOpened, setCouponViewOpened] = useState<boolean>(false)
    const [discountCode, setDiscountCode] = useState<string>('')
    const [appliedDiscount, setAppliedDiscount] = useState<DiscountResult>()
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [successMessage, setSuccessMessage] = useState<string>('')

    const [bookingDate, setBookingDate] = useState<string>()
    const [isMobileCartOpen, setIsMobileCartOpen] = useState(false)

    const {state, dispatch} = useContext(CartContext);

    useEffect(() => {
        if (props.inputCoupon) {
            processCouponCode(props.inputCoupon)
        }
    }, [props.inputCoupon])
    useEffect(() => {
        setTotalPrice(state.cart.price)
        if (state.cart.items.length > 0 ){
            setBookingDate(moment(state.cart.items[0].timeslot?.date, 'YYYY-MM-DD').format('DD MMM YYYY'))
        }
    }, [state.cart.items])

    useEffect(() => {
        
        const [percentDiscountVoucher] = state.cart.discounts
           setAppliedDiscount(percentDiscountVoucher)
           setDiscountCode(percentDiscountVoucher?.code ?? '')

   }, [state.cart.discounts])
    const processCouponCode = (discountCode: string) => {
        setErrorMessage('')
        if (appliedDiscount) {
            removeDiscount(appliedDiscount)
            return
        }
        const discounts = state.cart.discounts ?? []
        discounts.unshift({code: discountCode})
        const newCart = {
            ...state.cart,
            discounts: discounts
        }
        updateCart(newCart).then((result) => {

            if (result.hasOwnProperty("id")) {
                dispatch({type: Types.UpdateCart, payload: result})
            }
            const appliedDiscount = result.discounts?.find(x => x.code === discountCode)
            const appliedDiscountDesc = appliedDiscount?.description ?? ''
            if (appliedDiscount) {
                ReactGA._gtag("event", "selected_promotion", {
                    creative_name: appliedDiscount.type,
                    promotion_id: appliedDiscount.code,
                    promotion_name: appliedDiscount.name,
                })
                setSuccessMessage(t("Pomyślnie zaaplikowano kod"))
                setTimeout(() => {
                    setSuccessMessage('')
                }, 6000)
            }
        }).catch((errorResult: CartError) => {
            setErrorMessage(t(errorResult.errors.discounts.find(x => x.key === discountCode)?.message ?? ''))
            setTimeout(() => {
                setErrorMessage('')
            }, 6000)
            dispatch({type: Types.UpdateCart, payload: errorResult.fixedCart})
        
        })

    }
    const removeDiscount = (discount: DiscountResult) => {
        const discounts = state.cart.discounts?.filter(x => x.code !== discount.code) ?? []
        const newCart = {
            ...state.cart,
            discounts: discounts
        }
        updateCart(newCart).then((result) => {
            dispatch({type: Types.UpdateCart, payload: result})
        })
    }
    const canGoNextBeActive = () => {
       switch(props.activeIndex) {
        case 0:
        case 1:
            return state.cart.items.length > 0
        case 2:
            return state.cart.items.length > 0 && state.cart.items[0].timeslot
        case 3:
            return false
        default: 
            return false
        }
    }
    return(
        <div className={"bg-grey-50  rounded-lg "}>
            
            <div className="hidden lg:block   mt-16  px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8">
                
                <span className="text-lg font-bold text-gray-900">{t('Koszyk')}</span>
                <div>
                    <div className="my-4"></div>
                    {state.cart.items.length > 0 && state.cart.items[0].timeslot  && 
                    <div className={"mb-2  text-lg border-b pb-4"}>
                           
                            <div className="mb-2">
                                <span className="font-medium text-gray-900 ">{t('Rezerwacja')}:</span> 
                                <span className="float-right text-right font-bold">{bookingDate} {state.cart.items[0].timeslot?.time}</span>
                            </div>
                            <div className={"clear-both"} />
                    </div>
                    }
                    {state.cart.items.length > 0 && 
                        <div className={"py-4 mb-4  text-lg"}>
                                <div className="mb-2 flex justify-between">
                                <div className="font-medium text-gray-900 ">{state.cart.items[0].name}</div>
                                <div className={"font-medium text-gray-900"}>
                                    <span className=" text-lg font-bold text-dark">
                                        {getFormattedPrice(state.cart.items[0].price, true, currency)}
                                    </span>
                                    </div>
                                </div>
                                <div className="mt-1 text-sm text-gray-500">
                                    <span className="">{isBay(props.parentVariant) ? t("Liczba osi") : t("Liczba stanowisk")}:</span> <span className="float-right">{state.cart.items[0].quantity}</span>
                                </div>
                                <div className={"clear-both"} />
                                <span className="float-left mt-1 text-sm text-gray-500">
                                    {t('Długość')}:
                                </span>
                                <span className="float-right mt-1 text-sm text-gray-500">
                                    {state.cart.items[0].duration} {t('min')}
                                </span>
                                <div className={"clear-both"} />
                        </div>
                    }
                    
                    {appliedDiscount && appliedDiscount.type === 'PERCENT_COUPON' &&
                        <div>
                            <div className="flex border-t border-gray-200 pt-4">
                            <div className="text-sm font-medium text-gray-600 text-left w-1/2">{t('Cena przed rabatem')}</div>
                            <div className="text-sm text-gray-900  font-bold text-right w-1/2">{getFormattedPrice(state.cart.basePrice, true, currency)}</div>
                            </div>
                        <div className="flex mt-2">
                            
                            <div className="w-1/2">
                                <span className={"text-sm font-medium  text-green"}>{t('Rabat')} {appliedDiscount.value}%</span>
                            </div>
                            <div className="w-1/2">
                                {state.cart.basePrice - state.cart.price > 0 && <div
                                    className="text-sm font-medium text-right text-green"> - {getFormattedPrice(state.cart.basePrice - state.cart.price, true, currency)}</div>}
                            </div>
                        </div>
                        </div>
                    }
                  
                {appliedDiscount && appliedDiscount.type === 'MONEY_COUPON' &&
                <div className="flex mt-8">
                    <div className="w-1/2">
                        <span className={"font-bold text-green"}>{t('Rabat')} {getFormattedPrice(appliedDiscount.value ?? 0, true, currency)}</span>
                    </div>
                    <div className="w-1/2">
                        {state.cart.basePrice - state.cart.price > 0 && <div
                            className="text-xl font-bold text-right text-green"> - {getFormattedPrice(state.cart.basePrice - state.cart.price, true, currency)}</div>}
                    </div>
                </div>
                }
                {state.cart.items.length == 0 ? <div className="text-md text-gray-700 my-12">{t('Twój koszyk jest pusty')}</div> :  

                    <div className="flex border-t border-gray-200 pt-4 mt-4">
                        <div className="w-1/2">
                            
                            <span className={"text-base font-medium text-gray-900"}>{t('Łączna kwota')}</span><span className={"text-xs text-grey-500"}> {t('Z VAT')}</span>

                        </div>
                        <div className="w-1/2">
                            <div className="text-xl font-bold text-gray-900 text-right">
                                {getFormattedPrice(totalPrice, true, currency)}
                            </div>
                        </div>

                    </div>}
                   
        {props.isForwardButtonVisible && state.cart.items.length > 0 && 
        <div className="mt-6">
                {state.cart.items.length > 0 && state.cart.items[0].timeslot ? 
                    <button
                        className={classNames({
                            "shadow mt- font-bold bg-secondary w-full font-3xl py-2 text-white rounded hover:bg-secondary-lighter ": true,
                        })}
                        disabled={!state.cart.items[0].timeslot}

                        onClick={() => {
                            state.cart.items[0].timeslot && props.onForward()
                        }}>
                        {t('Kontynuuj')}
                    </button>
                    : <div className="inactive-go-next-button"><span>{t('Wybierz termin realizacji')}</span></div> }
            
        </div>
        }
         {state.cart.items.length >0 && 
                    <div className={"my-4"}>
                        <span className={"cursor-pointer text-secondary"} onClick={() => setCouponViewOpened(!couponViewOpened)}>{t('Mam kod rabatowy')}
                            <FiChevronDown className={classNames(
                                {
                                    "inline-block transform duration-150 ease-in-out mb-1": true,
                                    "rotate-180": couponViewOpened
                                })}/>
                        </span>
                        {
                            couponViewOpened &&
                            <div className={"mt-2"}>

                                <span className={"text-red"}>  </span>
                                <div className={!errorMessage && !successMessage ? "mb-10": ""}>
                                    <InputText className={"block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"}
                                                value={discountCode} onChange={e =>
                                        // @ts-ignore
                                        setDiscountCode(e.target.value)}
                                        onSubmit={() => processCouponCode(discountCode)}
                                                disabled={appliedDiscount != undefined}/>
                                        <button className="mt-3 rounded-md border border-transparent bg-secondary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-darker focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last  sm:w-auto "

                                            onClick={() => processCouponCode(discountCode)}
                                    >{appliedDiscount ? t("Usuń") : t("Dodaj")}</button>
                                </div>
                                {
                                    errorMessage && <span className={"text-red text-xs font-bold"}> {errorMessage} </span>
                                }
                                {
                                            successMessage && <span className={"text-green text-xs font-bold"}> {successMessage} </span>
                                        }
                            </div>
                        }
                    </div>}
                </div>
            </div>
            {(state.cart.items.length > 0 || (state.cart.discounts?.length ?? 0) > 0) &&
            <div className={classNames({
                "block lg:hidden": true,
                "hidden": props.isFinalIndex
            })}
            >

                <div className={classNames({"mobile-full-cart": true, "opened": isMobileCartOpen})}>
                    <div className="cart-items items-center">
                    <div className={"mt-4 mx-4 flex relative"}>
                            <div className="absolute cursor-pointer p-2 hover:bg-grey-100 rounded-lg"
                                 onClick={() => setIsMobileCartOpen(!isMobileCartOpen)}>
                                <FiChevronLeft/>
                            </div>
                            <div className={"mx-auto"}><span className="font-bold text-xl">{t('KOSZYK')}</span></div>
                        </div>
                        <div className="mx-4 pb-24 cart-content mt-10">
                        {state.cart.items.length > 0 && state.cart.items[0].timeslot  && 
                            <div className={"bg-white mb-2 rounded-lg text-lg"}>
                                    <div className="mb-1">
                                        <span className="font-bold">{t('REZERWACJA')}</span>
                                    </div>
                                    <div className="mb-1">
                                        <span className="font-bold">{t('Data i godzina')}</span> 
                                        <span className="float-right text-right">{bookingDate} <br/>{state.cart.items[0].timeslot?.time}</span>
                                    </div>
                                    <div className={"clear-both"}/>
                                    <span className="float-left font-bold">
                                        {t('Długość')}
                                    </span>
                                    <span className="float-right">
                                        {state.cart.items[0].duration} {t('min')}
                                    </span>
                                    <div className={"clear-both"} />
                            </div>
                            }

                            {state.cart.items.length > 0 && 
                                <div className={"bg-grey-100 p-4 mb-8 rounded-lg text-lg"}>
                                        <div className="mb-2">
                                            <span className="font-bold">{state.cart.items[0].name}</span>
                                        </div>
                                        <div  className="text-left text-grey-400 text-base">
                                        {isBay(props.parentVariant) ? t("Liczba osi") : t('Liczba stanowisk')}: {state.cart.items[0].quantity}
                                        </div>
                                </div>
                            }
                           
                             {appliedDiscount &&
                            <div className="flex mt-8">
                                <div className="w-1/2">
                                    <span className={"font-bold text-green"}>{t('Rabat')} {appliedDiscount.type === 'PERCENT_COUPON' ? appliedDiscount.value +"%":getFormattedPrice(appliedDiscount.value ?? 0, true, currency)}</span>
                                </div>
                                <div className="w-1/2">
                                    {(state.cart.basePrice - state.cart.price) > 0 && <div
                                        className="text-xl font-bold text-right text-green"> - {getFormattedPrice(state.cart.basePrice - state.cart.price, true, currency)}</div>}
                                </div>
                            </div>
                            }
                             <div className="flex">
                                <div className="w-1/2">

                                    <span className={"font-bold"}>{t('Suma')}</span><span className={"text-xs text-grey-400"}> {t('z vat')}</span>

                                </div>
                                <div className="w-1/2">
                                    <div className="text-xl font-bold text-right">{getFormattedPrice(state.cart.price, true, currency)}</div>
                                </div>
                            </div>
                            {state.cart.items.length >0 && 
                            <div className={classNames({"my-4": true})}>
                                <span className={"cursor-pointer text-secondary"} onClick={() => setCouponViewOpened(!couponViewOpened)}>{t('Mam kod rabatowy')}
                                    <FiChevronDown className={classNames(
                                        {
                                            "inline-block transform duration-150 ease-in-out mb-1": true,
                                            "rotate-180": couponViewOpened
                                        })}/>
                                </span>
                                {
                                    couponViewOpened &&
                                    <div className={"mt-2"}>
                                        <div>
                                            <InputText className={"text-center p-inputtext p-component inline-block mt-2"}
                                                       value={discountCode} onChange={e =>
                                                // @ts-ignore
                                                setDiscountCode(e.target.value)}
                                                       disabled={appliedDiscount !== undefined}/>
                                            <button className="p-2 inline-block bg-secondary text-white shadow rounded
                      hover:bg-secondary-lighter justify-center text font-bold mx-auto max-w-full mt-1 ml-2"

                                                    onClick={() => processCouponCode(discountCode)}
                                            >{appliedDiscount ? t("USUŃ") : t("DODAJ")}</button>
                                        </div>
                                        {
                                            !successMessage && errorMessage && <span className={"text-red text-xs font-bold"}> {t(errorMessage)} </span>
                                        }
                                        {
                                            successMessage && <span className={"text-green text-xs font-bold"}> {successMessage} </span>
                                        }
                                    </div>
                                }
                            </div>}
                        </div>
                    </div>
                </div>
                <div className={"w-full fixed left-0 bottom-0 border border-secondary rounded-full"}>
                    <div onClick={() => setIsMobileCartOpen(!isMobileCartOpen)}
                         className={"z-50 rounded-full absolute bg-secondary shadow-lg hover:bg-secondary-darker hover:border-secondary-darker cursor-pointer"}
                         style={{bottom: '20px', left: 'calc(50% - 30px)', height: '60px', width: '60px'}}>
                        <span
                            className={"bg-white rounded-lg text-primary absolute text-center text-sm cursor-pointer"}
                            style={{
                                height: '20px',
                                width: '20px',
                                left: '21px',
                                top: '5px'
                            }}>{(state.cart.items?.length ?? 0) + (state.cart.discounts?.filter(x => x.type === 'BUNDLE_VOUCHER')?.length ?? 0)}</span>
                        <FiShoppingCart className={"cursor-pointer"} size={25}
                                        style={{marginLeft: '16px', marginTop: '23px'}}/>
                    </div>
                    <div className={"inline-block bg-grey-100 rounded-lg absolute left-0 bottom-0 shadow-lg"} style={{
                        height: '65px',
                        width: 'calc(50% - 37.5px)',
                        borderBottomRightRadius: 0,
                        boxShadow: 'rgb(255 255 255 / 0.46) 1px -6px 16px 0px'
                    }}>
                        <div
                            className={"text-center text-xl mt-5 font-bold"}>{

                                state.cart.items.length > 0 && getFormattedPrice(state.cart.price, true, currency) }
                        </div>
                    </div>
                    <div className={"inline-block absolute"} style={{
                        width: '75px',
                        left: 'calc(50% - 37.5px)',
                        bottom: 0,
                        height: '50px',
                        backgroundImage: "url('/cart-mobile.svg')"
                    }}/>
                    <div className={"inline-block bg-grey-100 rounded-lg absolute right-0 bottom-0 shadow-lg"} style={{
                        height: '60px',
                        width: 'calc(50% - 37.5px)',
                        borderBottomLeftRadius: 0,
                        boxShadow: 'rgb(255 255 255 / 0.46) 1px -6px 16px 0px'
                    }}>
                        <div className="mt-2 mx-4">
                            {canGoNextBeActive() ? <button
                                    className={classNames({
                                        "shadow-lg bg-white text-dark font-bold w-full border-secondary border-2 py-2 hover:bg-secondary hover:border-secondary hover:text-white rounded": true,
                                        "bg-grey cursor-default rounded": props.activeIndex == 0 && (state.cart.items?.length ?? 0) + state.cart.discounts?.filter(x => x.type === "BUNDLE_VOUCHER").length == 0
                                    })}
                                    onClick={() => {
                                        props.onForward();
                                        setIsMobileCartOpen(false);
                                        posthog.capture('merch go_next clicked')
                                    }}>
                                        {t('Kontynuuj')}
                                </button>
                                :
                                <div
                                    className="inactive-go-next-button shadow-lg bg-white text-dark font-bold w-full border-secondary border-2 py-2 rounded">{t('Wybierz termin')}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default RangeCart
import React from 'react';
import classNames from "classnames";
import {BsCheck} from "react-icons/bs";
import {useTranslation} from "react-i18next";

import './PurchaseNavigation.scss'
import { BiChevronRight } from 'react-icons/bi';

interface PurchaseNavigationProps {
    model: MenuItem[];
    activeIndex:  number;
    onSelect(e: any): void;
}
export interface MenuItem {
    label: string;
    active?: boolean;
    shortLabel?: string;
}

export const PurchaseNavigation = (props: PurchaseNavigationProps) => {
    const {t} = useTranslation()
    return (
    <React.Fragment>
        <div className="px-2 md:px-0 h-30 bg-white border-solid  items-center flex purchase-navigation">
            <div className="relative p-steps p-component py-4 mt-8 mx-auto w-full lg:pl-3 md:mx-10 lg:mx-14 xl:mx-20 md:mx-3 flex mx-0 ">
                {props.model.map((el,index) => (
                    <React.Fragment key={index}>
                        <div className={classNames({
                            "mr-2 cursor-default":true,
                            "text-grey-400":index !== props.activeIndex,
                            "hover:text-primary cursor-pointer": el.active
                        })}
                        onClick={(el) => props.onSelect({index: index})}
                        >
                            {t(el.label)}
                        </div> 
                        {index < (props.model.length - 1) && 
                        <BiChevronRight className={classNames({"mr-2 mt-1 text-grey": true})}/>}
                    </React.Fragment> 
                ))}
                {/* <ul role="tablist" className="flex list-none">
                    {props.model.map((el,index) =>
                        <li
                            key={el.label}
                            className={classNames({
                            "p-steps-item": true,
                            "p-highlight p-steps-current": index === props.activeIndex,
                            "disabled cursor-default": el.active === false
                        })} aria-expanded="false" onClick={(el) => props.onSelect({index: index})}>
                            <a href="#" className="p-menuitem-link" role="presentation" tabIndex={index}>
                                <span className="px-4 bg-white z-10">
                            <span
                                className={classNames({
                                    "p-steps-number": true,
                                    "text-secondary-important": index < props.activeIndex
                                })}>
                                {index >= props.activeIndex ? index + 1 : <BsCheck/>}
                            </span>
                                </span>
                                <span className={classNames({
                                    "hidden sm:block": index !== props.activeIndex,
                                    "p-steps-title":true
                                })}>{t(el.label)}</span>
                            </a>
                        </li>

                    )}

                </ul> */}
            </div>
        </div>
    </React.Fragment>
    )
}
export default PurchaseNavigation;

import {useTranslation} from "react-i18next";
import { useContext, useEffect, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { MdWarning } from "react-icons/md";
import { CartContext } from "../../contexts/reducers";


type CartRuleNotModelModalProps = {
    dialogVisible: boolean,
    setDialogVisible: (value: boolean) => void,
    minPackages: number,
    currentPackagesCount: number
}
const CartRuleNotModelModal = (props: CartRuleNotModelModalProps) => {
    const {t} = useTranslation()
    const [missingCount, setMissingCount] = useState<number>(0)
    const {state} = useContext(CartContext)

    useEffect(() => {
        setMissingCount(props.minPackages - props.currentPackagesCount)
    }, [props.minPackages, props.currentPackagesCount])
    return (
        <Dialog open={props.dialogVisible} onClose={props.setDialogVisible} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
  
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-yellow-100">
                  <MdWarning aria-hidden="true" className="size-6 text-yellow-600" />
                </div>
                <div className="mt-3 text-left sm:mt-5">
                  <DialogTitle as="h3" className="text-base font-semibold text-gray-900 text-center">
                    {t("Za mało produktów w koszyku")}
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        {t("Rezerwacja jest możliwa przy minimalnej liczbie %d pakietów.").replace("%d",props.minPackages.toString())} {t(`Dodaj jeszcze %d szt. do koszyka, aby złożyć zamówienie.`).replace("%d", missingCount.toString())}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() =>props.setDialogVisible(false)}
                  className="inline-flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-darker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                >
                  {t("Wybierz dodatkowe produkty")}
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    )
}
export default CartRuleNotModelModal
import {useEffect, useState} from "react";
import {getClubs} from "../../utils/Club.service";
import Select from "react-select";
import {useTranslation} from "react-i18next";

type ClubSelectorType = {
    selectedClub: string,
    onSelectedClubChange: (clubName: string)=> void,
    priorityClubs:string[]
}
type ClubSelect = {
    value: string,
    label: string
}

const ClubSelector = (props: ClubSelectorType) => {
    const {t} = useTranslation()
    const [clubs, setClubs] = useState<ClubSelect[]>([])
    const [rawClubs, setRawClubs] = useState<string[]>([])
    useEffect(() => {
        getClubs().then(res => {
            setRawClubs(res.slice())
            res.unshift(...props.priorityClubs)
            setClubs(res.map((clubName) => ({value: clubName, label: clubName})))})
    }, [])
    return (
        <Select
            options={clubs}
            placeholder={"Wybierz"}
            onChange={(event) => props.onSelectedClubChange(event?.value ?? "")}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: 'var(--accent-color)',
                    primary25: 'var(--accent-color-dimmed)',

                }
            })}
            noOptionsMessage={() => "Brak klubu"}
            onKeyDown={(e)=>{ // @ts-ignore
                const value = e.target.value
                if (value.length > 0 && clubs.length > rawClubs.length) {
                    setClubs(rawClubs.map((clubName) => ({value: clubName, label: clubName})));
                }
                if (value.length == 0 && clubs.length == rawClubs.length) {
                    let newClubs = rawClubs.slice()
                    newClubs.unshift(...props.priorityClubs)
                    setClubs(newClubs.map((clubName) => ({value: clubName, label: clubName})))
                }
            }}
        />
    )
}

export default ClubSelector
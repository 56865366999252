import React, {useContext, useEffect, useRef, useState} from 'react';
import {Dialog} from "primereact/dialog";
import PackageItem from "./PackageItem";
import classNames from "classnames";
import {WeaponDialog} from "./WeaponDialog";
import {CartItem, CartItemOption} from "../../models/Cart.model";
import {BundleAttributes, BundleProduct, SellableProduct, SimpleProductAttributes} from "../../models/Product.model";
import {getImageUrl} from "../../utils/Api.service";
import PackageInfoDialogItem from "./PackageInfoDialogItem";
import ReactGA from "react-ga4";
import './PackageInfoDialog.scss';
import {AddToCartButton} from "./Buttons/AddToCartButton";
import {getFormattedPrice} from "../../utils/PriceUtils";
import {AiFillPlusCircle, AiFillMinusCircle} from "react-icons/ai";
import {getPackageLabel, getShortUnit, unitFormatter} from '../../utils/UnitUtils';
import {useTranslation} from "react-i18next";
import { CartContext } from '../../contexts/reducers';
import { TimeSlot } from '../../models/TimeSlot.model';
import { useCurrency } from '../../contexts/CurrencyProvider';
import { usePostHog } from 'posthog-js/react';
import { MdClose } from 'react-icons/md';

type PackageInfoDialogProps = {
    isDialogOpened: boolean,
    onIsDialogOpenedChange: (isDialogOpened: boolean) => void,
    package: SellableProduct & BundleAttributes,
    availableAdditions: (SellableProduct & SimpleProductAttributes)[],
    selectedAdditions: CartItem[],
    onSelectedAdditionsChange: (selectedAdditions: CartItem[]) => void,
    onAddToCartButtonClick: (selectedItems: SellableProduct[], timeslot?: TimeSlot, cartItemOptions?: CartItemOption[]) => void
}

export const PackageInfoDialog = (props: PackageInfoDialogProps) => {
    const {t} = useTranslation()
    const posthog = usePostHog()

    const {state} = useContext(CartContext)
    const {currency} = useCurrency()
    const configureRef = useRef(null)
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
    const [previewedPackage, setPreviewedPackage] = useState<SellableProduct | undefined>(undefined)
    const [image, setImage] = useState<string>('')
    useEffect(() => {
        const newImage = getImageUrl(props.package.sku, 'large')
        if (newImage !== image) {
            setImage(newImage)
        }
    });
    useEffect(() => {
        if (!props.isDialogOpened) {
            return;
        }
        ReactGA._gtag("event", "view_item", {
            currency: currency,
            value: (props.package.price / 100.00),
            "items": [{
                "item_id": props.package.sku,
                "item_name": props.package.name,
                "currency": currency,
                "price": (props.package.price / 100.00)
            }]
        })
        posthog.capture('merch product_viewed', {

            "item_id": props.package.sku,
            "item_name": props.package.name,
            "currency": currency,
            "price": (props.package.price / 100.00)
        })
    }, [props.isDialogOpened])

    const canItemBeDecremented = (product: SellableProduct) => {
        return props.selectedAdditions.some(x => x.sku === product.sku)
    }

    const isAdditionUsed = (product: SellableProduct) => {
        return props.selectedAdditions.some(x => x.sku === product.sku);
    }

    const getProductAddition = (product: SellableProduct) => {
        return props.selectedAdditions.find(x => x.sku === product.sku);
    }
    const isMoneyVoucher = () => {
        return props.package.customAttributes?.find(x => x.name === 'money-voucher' && x.value === 'true')
    }

    const incrementAdditionQty = (addition: SellableProduct & SimpleProductAttributes) => {
        const selectedAdditions = props.selectedAdditions;
        const selectedAdditionIndex = selectedAdditions.findIndex(x => x.sku === addition.sku);
        posthog.capture('merch upsell_added', {
            "item_id": addition.sku,
            "item_name": addition.name,
            "currency": currency,
            "price": (addition.price / 100.00)
        })
        if (selectedAdditionIndex >= 0) {
            const selectedAddition = selectedAdditions[selectedAdditionIndex]
            selectedAddition.quantity += 1
            props.onSelectedAdditionsChange([...selectedAdditions.filter(x => x.sku !== addition.sku), selectedAddition])
        } else {
            props.onSelectedAdditionsChange([...selectedAdditions, {
                sku: addition.sku,
                quantity: 1,
                name: addition.name,
                quantityMultiplier: addition.quantityMultiplier,
                description: addition.description,
                unit: addition.unit,
                price: addition.price,
                taxRate: addition.taxRate,
                duration: addition.duration ?? 0
            }])
        }
    }

    const decrementAdditionQty = (addition: SellableProduct) => {
        const selectedAdditions = props.selectedAdditions;
        const selectedAdditionIndex = selectedAdditions.findIndex(x => x.sku === addition.sku);
        posthog.capture('merch upsell_removed', {
            "item_id": addition.sku,
            "item_name": addition.name,
            "currency": currency,
            "price": (addition.price / 100.00)
        })
        if (selectedAdditionIndex >= 0) {
            const selectedAddition = selectedAdditions[selectedAdditionIndex]
            selectedAddition.quantity -= 1
            if (selectedAddition.quantity > 0) {
                props.onSelectedAdditionsChange([...selectedAdditions.filter(x => x.sku !== addition.sku), selectedAddition])
            } else {
                deleteAddition(addition)
            }
        }
    }
    const getPackageUnit = () => {
        let defaultValue = "MINUTE"
        if (props.package.type === 'BUNDLE'){
            props.package.children.forEach((el:BundleProduct) => {if (el.product.unit !== "MINUTE") {defaultValue = el.product.unit}})
        }
        return defaultValue
    }
    const deleteAddition = (addition: SellableProduct) => {
        props.onSelectedAdditionsChange([...props.selectedAdditions.filter(x => x.sku !== addition.sku)])
    }

    const additionalprice = props.selectedAdditions.reduce((a, b) => {
        return a + b.price * b.quantity
    }, 0)

    const totalUnits = (unit: String = "SHOT") => props.package.children.filter((x:BundleProduct) => x.product.unit === unit).reduce((acc:number, item:BundleProduct) => {
        return acc + item.product.quantityMultiplier * item.quantity
    }, 0)

    const additionalUnits = (unit: String = "SHOT") => props.selectedAdditions.filter(x => x.unit === unit).reduce((acc, item) => {
        return acc + (item.quantityMultiplier ?? 0) * item.quantity
    }, 0)

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return <React.Fragment>
        <Dialog 
            visible={props.isDialogOpened}
            className="package-info-dialog relative max-h-full md:h-vh90 h-full md:w-9/10 bg-white rounded-lg"
            onHide={() => props.onIsDialogOpenedChange(false)}
            dismissableMask={true}
            closable={false}
            draggable={false}
            >
                <div
                    
                    className={"absolute top-1 right-2 md:top-2 md:right-4 p-2 hover:bg-grey rounded cursor-pointer z-20 text-primary"}
                    onClick={() => props.onIsDialogOpenedChange(false)}
                >
                    <MdClose size={25}/>
                </div>
            <div className={"fixed flex sm:hidden bottom-0 bg-white w-full p-4 pb-1 z-50 justify-between"}>
                    <div className={"mr-2"}>
                        <button
                            onClick={() => {
                                // @ts-ignore
                                configureRef.current.scrollIntoView({behavior: 'smooth'});
                            } }
                            className={"items-center p-1 text-primary shadow-lg rounded w-full border-2 hover:bg-secondary border-secondary flex justify-center text font-bold mx-auto mt-1  mb-4 max-w-full "}>
                            <span className={"text p-1 sm:p-2 "}>{t('Rozbuduj')}</span>
                        </button>
                    </div>
                    <AddToCartButton className={"add-to-cart-small ml-auto"}
                                     onAddToCartClick={() => {
                                         props.onIsDialogOpenedChange(false);
                                         props.onAddToCartButtonClick([])
                                     }}
                                     disabled={props.package.children.length === 0 && props.selectedAdditions.length === 0 && !isMoneyVoucher()}/>
            </div>
            <div className={"box-border w-full lg:w-1/2 inline-block"}>
                <div className={"hidden lg:block h-vh90 left-0 top-0 absolute w-1/2"}>
                    <img className=" md-4 object-contain object-left-top rounded-tl-lg" src={image}/>
                </div>
                <img className="lg:hidden w-full md-4 object-contain rounded-lg" style={{"maxHeight": "300px"}} src={image}/>
            </div>
            <div className={"pl-4 md:pl-8  w-full lg:w-1/2 inline-block align-top"}>
                <div className={"px-4 mt-2 sm:mt-0 mb-2"}>
                   
                    <span className={"text-3xl font-semibold tracking-tight text-gray-900"}>{props.package.name}</span><br/>
                    <div className={"text-secondary  md:pt-1"}>
                        {totalUnits("SHOT") +additionalUnits("SHOT") > 0 &&
                        <React.Fragment>
                            {totalUnits() + (totalUnits() == 0 ? additionalUnits() : 0)} {additionalUnits() > 0 && totalUnits() > 0 && <span className={"text-secondary"}> + {additionalUnits()} </span>}
                            {unitFormatter("SHOT", totalUnits() + additionalUnits(), true, state.language)}
                        </React.Fragment>
                        }
                    </div>
                    <span className={"text-grey-400"}>
                    {totalUnits("MINUTE") + additionalUnits("MINUTE") > 0 &&
                    <React.Fragment>
                        {totalUnits("MINUTE") + (totalUnits("MINUTE") == 0 ? additionalUnits("MINUTE") : 0)} {additionalUnits("MINUTE") > 0 && totalUnits("MINUTE") > 0 &&
                        <span className={"text-secondary"}> + {additionalUnits("MINUTE")}</span>}
                        {unitFormatter("MINUTE", totalUnits("MINUTE") + additionalUnits("MINUTE"), true, state.language)}
                    </React.Fragment>
                    }
                    </span>

                    <div className="flex justify-between">
                        <div className={"font-semibold flex items-center mt-4 md:mt-0"}>
                            <div className={"text-2xl tracking-tight text-gray-900"}>
                                {getFormattedPrice(props.package.price + (totalUnits() === 0 ? additionalprice : 0 ), true, currency)}
                            </div>

                            <div>
                                {(props.package.children.length == 0 && props.package.price && (additionalUnits() + additionalUnits("MINUTE") == 0)) && !isMoneyVoucher() ?
                                <span className={"font-base text-grey-700 tracking-tight pr-2 ml-1"}>{t('(Opłata wejściowa)')}</span> : ""
                                }
                                {
                                    additionalprice > 0 && totalUnits() +totalUnits("MINUTE")> 0 &&
                                    <span className={"text-secondary text-2xl tracking-tight pl-2"}>+ {getFormattedPrice(additionalprice, true, currency)}</span>
                                }
                            </div>
                        </div>
                        <div className={"hidden sm:block pb-2 pt-2 flex lg:flex-none mr-4"}>
                            <AddToCartButton className={"add-to-cart-small"}  onAddToCartClick={() => {
                                props.onIsDialogOpenedChange(false);
                                props.onAddToCartButtonClick([])
                            }} disabled={props.package.children.length === 0 && props.selectedAdditions.length === 0 && !isMoneyVoucher()}/>

                        </div>
                    </div>
                </div>

                {props.package.children.length > 0 &&
                <div className={"px-4 md:py-2 md:px-4 md:border-b mt-4"}>
                    <div className={"font-bold"}>{t('Zawartość')}:</div>
                    <div className={"flex flex-wrap items-strech mt-4"}>
                        {props.package.children.map(((weapon:BundleProduct) => <PackageItem item={weapon} key={weapon.product.sku}/>))}
                    </div>
                </div>
                }
                <div className={"px-4 md:py-2 md:border-t border-gray-100"}>
                    <div className={"font-bold  pb-4 pt-4"}>{t('Szczegóły')}</div>
                    <span
                     dangerouslySetInnerHTML={{__html: props.package.description.replace(/(?:\r\n|\r|\n)/g, '<br>')}}/>
                </div>

                { !isMoneyVoucher() && 
                <div className={"px-4 mb-14 md:mb-0 md:p-4 mt-4"} ref={configureRef}>
                    <div className={"font-bold pb-4"}> {props.package.children.length > 0  ? <span>{t('Dodaj więcej')}:</span> :
                        <span> {t('Zbuduj własną przygodę')}</span>}</div>
                    <div>
                        {
                            props.availableAdditions.map((kit =>
                                <div key={kit.id} className="w-1/2 md:w-1/3 inline-block mb-2 h-46 align-top">
                                    <div
                                        className={classNames({
                                            "rounded  ml-1 mr-1 text-center rounded align-top relative p-2 bg-grey-50": true,
                                            "border-2 border-grey-50": !isAdditionUsed(kit),
                                            "border-secondary border-2": isAdditionUsed(kit)
                                        })}
                                    >
                                        {isAdditionUsed(kit) &&
                                        <div
                                            className="absolute -right-4 -top-4 rounded-full z-10 bg-white border-2  border-secondary w-13 h-13 leading-8 text-center flex justify-center">
                                            <span className={"font-bold text-xs leading-12"}>
                                                +{getFormattedPrice((getProductAddition(kit)?.quantity ?? 0) * kit.price, false, currency)}
                                            </span>
                                        </div>
                                        }
                                        <div className="product-description">
                                            <PackageInfoDialogItem item={kit} onItemClicked={() => {
                                                setPreviewedPackage(kit);
                                                setIsDialogOpened(true)
                                            }}/>
                                            <div className={"justify-center mb-2"}>
                                                <button onClick={() => decrementAdditionQty(kit)}
                                                        disabled={!canItemBeDecremented(kit)}
                                                        className={"disabled:text-grey-300 inline-block text-3xl text-secondary rounded-full hover:cursor-pointer hover:text-secondary-lighter"}>
                                                    <AiFillMinusCircle/>
                                                </button>
                                                <div
                                                    className="inline-block mx-1 text-sm align-top mt-2">

                                                    {(getProductAddition(kit)?.quantity ?? 0) * kit.quantityMultiplier} {getShortUnit(kit.unit, (getProductAddition(kit)?.quantity ?? 0) * kit.quantityMultiplier, state.language)}
                                                </div>
                                                <button onClick={() => incrementAdditionQty(kit)}
                                                        className={"disabled:text-grey-300 inline-block text-3xl text-secondary rounded-full hover:cursor-pointer hover:text-secondary-lighter"}>
                                                    <AiFillPlusCircle/>
                                                </button>
                                            </div>
                                            <div className={"justify-center mb-2"}>
                                                <div className=" inline-block mx-2 text-sm">{getFormattedPrice(kit.price, true, currency)} / {
                                                    // @ts-ignore
                                                    getPackageLabel(kit, [], 1, state.language).map((label, index) => (label))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>))
                        }
                    </div>
                </div>}
            </div>
            {
                previewedPackage && <WeaponDialog isDialogOpen={isDialogOpened} onHide={() => setIsDialogOpened(false)}
                                                  item={previewedPackage}/>
            }

        </Dialog>
    </React.Fragment>
}

export default PackageInfoDialog;

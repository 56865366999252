import {SellableProduct} from "../models/Product.model";
import {CartItem} from "../models/Cart.model";

export function unitFormatter(unit: string|undefined, quantity: number, skipValue: boolean = false, lang: 'PL'|'DE'|'EN' = 'PL'): string {
    const translationTablesShots = {
        'PL': ["strzałów", "strzał", "strzały"],
        'EN': ["shots", "shot", "shots"],
        'DE': ["Schüsse", 'Schuss', 'Schüsse']
    }
    const translationTablesMinutes = {
        'PL': ["minut", "minuta", "minuty"],
        'EN': ["minutes", "minute", "minutes"],
        'DE': ["Minuten", 'Minute', 'Minuten']
    }
    const translationTablesPiece = {
        'PL': ["sztuk", "sztuka", "sztuki"],
        'EN': ["pieces", "piece", "pieces"],
        'DE': ["Schüsse", 'Stück', 'Stücke']
    }
    const translationTablesBays = {
        'PL': ["osi", "oś", "osie"],
        'EN': ["bays", "bay", "bays"],
        'DE': ["Boxen", 'Box', 'Boxen']
    }
    if (unit === "SHOT") {
        return num(quantity, translationTablesShots[lang], skipValue);
    } else if (unit === "MINUTE") {
        return num(quantity, translationTablesMinutes[lang], skipValue);
    } else if (unit === "PIECE") {
        return num(quantity, translationTablesPiece[lang], skipValue)
    } else if (unit === "BAY") {
        return num(quantity, translationTablesBays[lang], skipValue)
    }
    return ""
}

export function getShortUnit(unit: string|undefined, quantity: number, lang: 'PL'|'EN'|'DE' = 'PL')
{
    const translationTable = {
        'PL': {
            shot: 'strz.',
            min: 'min',
            unit: 'szt.'
        },
        'EN': {
            shot: 'shots',
            min: 'min',
            unit: 'pc.'
        },
        'DE': {
            shot: 'Schü.',
            min: 'Min',
            unit: 'St.'
        }
    }
    if (unit === "SHOT" || unit === undefined) {
        return translationTable[lang].shot
    } else if (unit === "MINUTE") {
        return  translationTable[lang].min
    } else if (unit === "PIECE") {
        return translationTable[lang].unit
    }
    return ""
}
var num = function (value: number, numerals: string[], wovalue: boolean) {
    var t0 = value % 10,
        t1 = value % 100,
        vo = [];
    if (wovalue !== true)
        vo.push(value);
    if (value === 1 && numerals[1])
        vo.push(numerals[1]);
    else if ((value === 0 || (t0 >= 0 && t0 <= 1) || (t0 >= 5 && t0 <= 9) || (t1 > 10 && t1 < 20)) && numerals[0])
        vo.push(numerals[0]);
    else if (((t1 < 10 || t1 > 20) && t0 >= 2 && t0 <= 4) && numerals[2])
        vo.push(numerals[2]);
    return vo.join(' ');
};

export function getPackageLabel(item: SellableProduct, additions: CartItem[] = [], multiplier: number = 1, lang: 'PL'|'EN'|'DE' = 'PL') {
    if (item.type === "SIMPLE") {
        //@ts-ignore
        return [unitFormatter(item.unit, item.quantityMultiplier * multiplier, false, lang)]
    } else if (item.type === "BUNDLE" || item.type === "EXTENSION") {
        var units = {
            "MINUTE": 0,
            "SHOT": 0,
        }
         //@ts-ignore
        item.children.forEach((elem) => {
            if (elem.product.unit === "MINUTE") {
                units.MINUTE += elem.quantity * elem.product.quantityMultiplier
            } else if (elem.product.unit === "SHOT") {
                units.SHOT += elem.quantity * elem.product.quantityMultiplier
            }
        })

        additions.forEach((addition) => {
            const additionValues = getCartItemValues(addition)
            units.MINUTE += additionValues.MINUTE
            units.SHOT += additionValues.SHOT
        })
        var result = []
        if (units.SHOT > 0) {
            result.push(unitFormatter("SHOT", units.SHOT, false, lang))
        }
        if (units.MINUTE >0) {
            result.push(unitFormatter("MINUTE", units.MINUTE, false, lang))
        }
        return result
    }
    return []
}


export function getCartItemLabel(cartItem: CartItem, lang: 'PL'|'EN'|'DE' = 'PL')
{
    var units = {
        "MINUTE": 0,
        "SHOT": 0,
        "PIECE": 0,
    }
    if (cartItem.unit === "MINUTE") {
        units.MINUTE = (cartItem.quantityMultiplier  ?? 1) * cartItem.quantity 
    } else if (cartItem.unit === "SHOT") {
        units.SHOT = (cartItem.quantityMultiplier ?? 1)  * cartItem.quantity
    } else if (cartItem.unit === "PIECE") {
        units.PIECE = (cartItem.quantityMultiplier ?? 1)  * cartItem.quantity
    }
    cartItem.children?.forEach((item) => {
        if (item.unit === "MINUTE"){
            units["MINUTE"] += (item.quantityMultiplier ?? 1) * item.quantity
        } else if (item.unit === "SHOT") {
            units["SHOT"] += (item.quantityMultiplier ?? 1) * item.quantity
        }  else if (item.unit === "PIECE") {
            units["PIECE"] += (item.quantityMultiplier ?? 1) * item.quantity
        }
    })
    const result = []
    if (units.SHOT > 0) {
        result.push(unitFormatter("SHOT", units.SHOT, false, lang))
    }
    if (units.MINUTE >0) {
        result.push(unitFormatter("MINUTE", units.MINUTE, false, lang))
    }
    if (units.PIECE > 0) {
        result.push(unitFormatter("PIECE", units.PIECE, false, lang))
    }

    return result
}

const getCartItemValues = (cartItem: CartItem) => {
    var units = {
        "MINUTE": 0,
        "SHOT": 0,
        "PIECE": 0,
    }
    if (cartItem.unit === "MINUTE") {
        units.MINUTE = (cartItem.quantityMultiplier ?? 1)* cartItem.quantity
    } else if (cartItem.unit === "SHOT") {
        units.SHOT = cartItem.quantity * (cartItem.quantityMultiplier ?? 1)
    }else if (cartItem.unit === "PIECE") {
        units.PIECE = cartItem.quantity * (cartItem.quantityMultiplier ?? 1)
    }
    cartItem.children?.forEach((item) => {
        if (item.unit === "MINUTE"){
            units["MINUTE"] += item.quantityMultiplier ?? 1 * item.quantity
        } else if (item.unit === "SHOT") {
            units["SHOT"] += item.quantityMultiplier ?? 1* item.quantity
        } else if (item.unit === "PIECE") {
            units["PIECE"] += item.quantityMultiplier ?? 1* item.quantity
        }
    })
    
    return units
}
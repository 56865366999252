import React, { useState, useEffect, useContext } from "react"
import { RangeGroupProduct, RangeVariant, SellableProduct, Variant } from "../../models/Product.model"
import classNames from "classnames"
import { getImageUrl } from "../../utils/Api.service"
import { getFormattedPrice } from "../../utils/PriceUtils"
import LoadableImage from "../Image/LoadableImage"
import { Checkbox } from "primereact/checkbox"
import { CartContext, Types } from "../../contexts/reducers"
import { BiTable } from "react-icons/bi"
import { Button } from "primereact/button"
import ReactGA from 'react-ga4';
import {useTranslation} from "react-i18next";
import { useCurrency } from "../../contexts/CurrencyProvider"
import { usePostHog } from "posthog-js/react"
import { useTenant } from "../../contexts/TenantProvider"
import ReactPixel from 'react-facebook-pixel';
import { unitFormatter } from "../../utils/UnitUtils"
import { isBay, isLane } from "../../utils/RangeTypeDetector"


type RangeOtySelectorProps = {
    variant?: Variant & RangeVariant,
    parent?: SellableProduct,
    onForward: (selectedVariant: Variant, selectedQty: number) => void,
    onShowAvailability: (range: SellableProduct & RangeGroupProduct, variantSku: string) => void,
    onChangeLegalRequirement: (newValue: boolean) => void,
    isLegalRequiremendChecked: boolean,
}
export const RangeQtySelector = (props: RangeOtySelectorProps) => {
    const {t} = useTranslation()
    const {currency} = useCurrency()
    const {isTenantRequiredShootingLeaderPermission} = useTenant()
    const posthog = usePostHog()
    const [selectedVariant, setSelectedVariant] = useState<Variant>()
    const [baseVariants, setBaseVariants] = useState<Array<Variant>>([])
    const [alternativeVariants, setAlternativeVariants] = useState<Array<Variant>>([])
    const [variantsToDisplay, setVariantsToDisplay] = useState<Array<Variant>>([])
    const [isRangeSelector, setIsRangeSelector] = useState<boolean>(false)
    const [price, setPrice] = useState<number>(99)
    const [selectedQty, setSelectedQty] = useState<number>(1)
    const [selectedQtyType, setSelectedQtyType] = useState<string>('base');
    const [selectedQtyIndex, setSelectedQtyIndex] = useState<number>(0)
    const [image, setImage] = useState<string>('')
    const {state, dispatch} = useContext(CartContext);
    const [enableBayLaneSelector, setEnableBayLaneSelector] =useState<boolean>(false)
    const [bayLaneSelector, setBayLaneSelector] = useState<string>('')

    const [isShootingLeaderRequired, setIsShootingLeaderRequired] = useState(true);

    const [qtyOptions, setQtyOptions] = useState<Array<{qty: number, label: string, priceMultiplier: number, type: string}>>([])
    useEffect(() => {

        setIsRangeSelector(isLane(props.variant))
        setPrice(props.variant?.product.price ?? 0)
        setImage(getImageUrl(props.parent?.sku ?? '', 'large'))
        setSelectedVariant(props.variant?.product.variants[0])
        setPrice(props.variant?.product.variants[0].product.price ?? 0)
        //@ts-ignore
        setBaseVariants(props.variant.product.variants)
        //@ts-ignore
        setAlternativeVariants(props.parent?.variants.find(x => isBay(x))?.product.variants ?? null)
        //@ts-ignore
        const variants = props.parent?.variants.reduce((arr,elem) => [...arr, ...elem.product.variants], [])
        //@ts-ignore
        const filtered = variants.filter((obj: any,index: number) => variants.findIndex(item=> item.value === obj.value) === index)
        //@ts-ignore
        setVariantsToDisplay(filtered)
    }, [])

    
    useEffect(() => {
            const variantDuration =selectedVariant?.product.duration ?? 0

            let quantityOptions = []
            let hasMaster = false
            //@ts-ignore
            let fullRangeOption = props.parent?.variants.find(x => isBay(x))?.product.variants.find(y => y.value === selectedVariant?.value) ?? false
            //@ts-ignore
            let laneOption = props.parent?.variants.find(x => isLane(x))?.product.variants.find(y => y.value === selectedVariant?.value)
            //@ts-ignore
            if (laneOption) {

                const rangeProduct = fullRangeOption ? fullRangeOption.product.price : Number.MAX_VALUE
                const basePrice = laneOption?.product.price ?? 0
                for (let i = 0; i < (props.variant?.product?.limit ?? 0); i++) {
                    const qty = i + 1
                    const label = `${i + 1}`
                    const priceMultiplier = (i + 1)
                    const price  = (i + 1) * basePrice 
                    const type = 'base'
                    if (price <= rangeProduct ) {
                        quantityOptions.push({qty, label, priceMultiplier, type})
                    } else {
                        if (!hasMaster) {
                            hasMaster = true
                            quantityOptions.push({qty: 1, label: t("Cała oś"), priceMultiplier: 1, type: 'upsell'})
                        }
                    }
                }
                // dodaj stanowiska
            } if  (fullRangeOption) {
                quantityOptions = quantityOptions.filter( x=> x.type !== 'upsell')
                 //@ts-ignore
                const numberOfBays = props.parent?.variants.find(x => isBay(x)).product.limit
                if (numberOfBays === 1) {
                    quantityOptions.push({qty: 1, label: t("Cała oś"), priceMultiplier: 1, type: 'upsell'})
                } else {
                    //If there is at least one lane reservation option
                    if (quantityOptions.find(x => x.type === 'base')) {
                        setEnableBayLaneSelector(true)
                    }
                    for(let i=1; i<numberOfBays+1; i++)  {quantityOptions.push({qty: i, label:  i.toString(), priceMultiplier: i, type: 'upsell'})}
                }
            }
          
            ReactGA._gtag("event", "view_item", {
                currency: currency,
                value: ((props.parent?.price ?? 0) / 100.00),
                "items": [{
                    "item_id": props.parent?.sku ?? '',
                    "item_name": props.parent?.name ?? '',
                    "currency": currency,
                    "price": (props.parent?.price ?? 0) / 100.00
                }]
            })

            posthog.capture('merch product_viewed', {

                "item_id": props.parent?.sku ?? '',
                "item_name": props.parent?.name ?? '',
                "currency": currency,
                "price": ((props.parent?.price ?? 0) / 100.00)
            })
          
            setQtyOptions(quantityOptions)
            if (quantityOptions.length  < selectedQty) {
                setSelectedQty(1)
                if (selectedVariant) {
                    setPrice(selectedVariant?.product.price ?? 0)
                }
            }
        
    }, [selectedVariant])
    const clearCart = () => {
        if (state.cart.items.length > 0) {
            // dispatch({type: Types.Delete, payload:state.cart.items[0]})
            dispatch({type: Types.RemoveTimeslot, payload:null})
        }
    }
    const showAvailbility = () => {
         //@ts-ignore
        return  props.parent?.variants.find(x => isLane(x)) && !props.parent?.tags.find(x => x.key === 'throw')
    }
    const openAvailability = () => {
        if (props.parent) {
        //@ts-ignore
        props.onShowAvailability(props.parent, props.variant.product.sku)
        posthog.capture('merch range show_availability', {
            "item_id": props.parent?.sku ?? '',
            "item_name": props.parent?.name ?? '',
            "currency": currency,
            "price": ((props.parent?.price ?? 0) / 100.00)
        })
    }
    }
    const selectVariant = (qtyOption: {qty: number, label: string, priceMultiplier: number, type: string} ) => {
        const newVariant = (qtyOption.type === 'upsell') ? alternativeVariants.find( x=> x.value === selectedVariant?.value) : baseVariants.find( x=> x.value === selectedVariant?.value) ?? baseVariants[0]
        setSelectedVariant(newVariant)
        setPrice(qtyOption.priceMultiplier * (newVariant?.product.price ?? 1))
        setSelectedQty(qtyOption.qty)
        setSelectedQtyType(qtyOption.type)
        clearCart()
    }
    return (
        <React.Fragment>
            <div className="lg:flex relative ">
                <div className={"w-full mt-4"}>
                    <div className={"bg-white pb-8"}>
                        
                        <div className={"box-border w-full lg:w-1/2 inline-block"}>
                            <div className={"hidden lg:block  left-0 top-0 "}>
                            <LoadableImage
                                classes={"h-full w-full md-4 object-contain object-left-top rounded-lg"}
                                thumbnailData={"data:" + props.parent?.thumbnailType + ";base64, " + props.parent?.thumbnail}
                                imageSrc={image}
                                previewAvailable={false}
                            />
                            </div>
                            <LoadableImage
                                classes={"lg:hidden w-full md-4 object-contain max-h-250 rounded-lg"}
                                thumbnailData={"data:" + props.parent?.thumbnailType + ";base64, " + props.parent?.thumbnail}
                                imageSrc={image}
                                previewAvailable={true}
                            />
                        </div>
                        <div className={"w-full lg:w-1/2 inline-block align-top bg-white"}>
                            <div className={"px-4"}>
                                <div>
                                    <div className={"text-3xl font-semibold tracking-tight text-gray-900"}>{props.parent?.name}
                                    {showAvailbility() && 
                                        <span className="text-secondary text-sm font-medium align-middle cursor-pointer float-right pt-2" onClick={() => {openAvailability()}}>
                                      {t('Sprawdź dostępność')}
                                        </span>}
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p className="text-2xl tracking-tight text-gray-900">{getFormattedPrice(price, true, currency)}</p>
                                    </div>
                                    <div className="text-sm font-semibold text-gray-600 mt-6">
                                        {props.variant?.product.variants[0].name}
                                    </div>
                                    
                                    <div className={"mt-2 flex flex md:inline-flex flex-wrap w-full pb-2"}>
                                        {variantsToDisplay.map((variant: Variant) => (
                                            <div
                                                onClick={() => {
                                                    const selectedItem = variant
                                                    if (enableBayLaneSelector) {
                                                        setBayLaneSelector('')
                                                    }
                                                    setSelectedVariant(selectedItem)
                                                    setSelectedQty(1)
                                                    setSelectedQtyIndex(0)
                                                    setPrice(selectedItem.product.price * 1)
                                                    clearCart()
                                                }}
                                                key={variant.product.id}
                                                className={classNames({
                                                    "text-sm cursor-pointer rounded  px-3 py-2 mr-4 last:mr-0 mb-4 hover:border-secondary border-2": true,
                                                    "border-secondary": selectedVariant?.value === variant.value
                                                })}
                                            >{variant.value}</div>
                                        ))}
                                    </div>
                                    {qtyOptions.length > 0 && <>
                                    <div className="pt-4  border-t">
                                        <span className="text-sm font-semibold text-gray-600 mt-6">
                                            {!enableBayLaneSelector && (isBay(props.variant) ? t("Liczba osi") : t("Liczba stanowisk"))}
                                            {enableBayLaneSelector && t("Typ rezerwacji")}

                                        </span>
                                        {enableBayLaneSelector && <>
                                            <div className={"mt-2 flex mb-4 flex md:inline-flex flex-wrap w-full"}>
                                                <div
                                                onClick={() => {setSelectedQtyIndex(0); selectVariant(qtyOptions.filter(x => x.type === 'base')[0]); setBayLaneSelector('base')}} 
                                                className={classNames({
                                                    "cursor-pointer rounded text-center min-w-52px px-4 py-2 mr-4 last:mr-0 mb-2 hover:border-secondary border-2": true,
                                                    "border-secondary": bayLaneSelector === 'base',
                                                })}>
                                                    {t("Rezerwacja stanowiska")}
                                                </div>
                                                <div 
                                                onClick={() => {setSelectedQtyIndex(0);selectVariant(qtyOptions.filter(x => x.type === 'upsell')[0]); setBayLaneSelector('upsell')}} 
                                                className={classNames({
                                                    "cursor-pointer rounded text-center min-w-52px px-4 py-2 mr-4 last:mr-0 mb-2 hover:border-secondary border-2": true,
                                                    "border-secondary": bayLaneSelector === 'upsell',
                                                })}>
                                                    {t("Rezerwacja osi")}
                                                </div>
                                                </div>
                                        </>}
                                        {enableBayLaneSelector &&  bayLaneSelector &&
                                        <div className=" border-t pt-4">
                                            <span className="text-xl font-bold">
                                                {bayLaneSelector=== 'upsell' && t("Liczba osi")}
                                                {bayLaneSelector=== 'base' && t("Liczba stanowisk")}
                                            </span>
                                            </div>}
                                    </div>
                                    <div className={" flex mb-4 flex md:inline-flex flex-wrap w-full  border-b pt-2 pb-2"}>
                                        
                                         {!enableBayLaneSelector && qtyOptions.map((qtyOption,index) => (
                                            <div
                                                onClick={() => {
                                                    setSelectedQtyIndex(index)
                                                    selectVariant(qtyOption)                                                  
                                                }}
                                                key={index}
                                                className={classNames({
                                                    "text-center text-sm cursor-pointer rounded min-w-42px px-3 py-2 mr-4 last:mr-0 mb-4 hover:border-secondary border-2": true,
                                                    "border-secondary": index === selectedQtyIndex,
                                                    "px-4": qtyOption.label === "Cała oś",
                                                })}
                                            >{ qtyOption.label}</div>
                                        ))}
                                        {enableBayLaneSelector && qtyOptions.filter(x => x.type === bayLaneSelector).map((qtyOption,index) => (
                                            <div
                                                onClick={() => {
                                                    setSelectedQtyIndex(index)
                                                    if (qtyOption.type === 'upsell') {
                                                        //setVariantsToDisplay(alternativeVariants)
                                                        const newVariant = alternativeVariants.find( x=> x.value === selectedVariant?.value)
                                                        setSelectedVariant(newVariant)
                                                        setPrice(qtyOption.priceMultiplier * (newVariant?.product.price ?? 0))
                                                        setSelectedQty(qtyOption.qty)
                                                        setSelectedQtyType('upsell')

                                                    } else {
                                                        //setVariantsToDisplay(baseVariants)
                                                        const newVariant = baseVariants.find( x=> x.value === selectedVariant?.value) ?? baseVariants[0]
                                                        setSelectedVariant(newVariant)
                                                        setSelectedQty(qtyOption.qty)
                                                        setPrice(qtyOption.priceMultiplier * (newVariant?.product.price ?? 0))
                                                        setSelectedQtyType('standard')
                                                    }
                                                    clearCart()
                                                   
                                                }}
                                                key={index}
                                                className={classNames({
                                                    "text-center text-sm cursor-pointer rounded min-w-42px px-3 py-2 mr-4 last:mr-0 mb-4 hover:border-secondary border-2": true,
                                                    "border-secondary": index === selectedQtyIndex,
                                                    "px-4": qtyOption.label === "Cała oś",
                                                })}
                                            >{ qtyOption.label}</div>
                                        ))}
                                    </div>
                                    </>}
                                    {isTenantRequiredShootingLeaderPermission() && ((!isRangeSelector || selectedQty > 1 || selectedQtyType === 'upsell') && !props.parent?.tags?.find(x => x.key === 'throw') ) && 
                                        <div className="">
                                            <div className="w-full mb-8 flex">
                                                <Checkbox inputId="agreementLegal"
                                                        name="agreementLegal"
                                                        checked={props.isLegalRequiremendChecked}
                                                        className={classNames({'p-invalid': ( !isRangeSelector || selectedQty > 1 || selectedQtyType === 'upsell') && !props.isLegalRequiremendChecked})}
                                                        onChange={(e) => {props.onChangeLegalRequirement(e.checked ?? false); posthog.capture('merch confirmed_instructor_license')}}/>
                                                <label htmlFor="agreementLegal" className="p-checkbox-label ml-4 text-sm font-gray-600 align-mddle">
                                                   {t('Posiadam uprawnienia prowadzącego strzelanie.')}
                                                </label>
                                            </div>
                                        </div>
                                    }
                                    <div className="mt-4">
                                      
                                        <button 
                                            disabled={isTenantRequiredShootingLeaderPermission() && ( !isRangeSelector || selectedQty > 1 || selectedQtyType === 'upsell') && !props.parent?.tags?.find(x => x.key === 'throw') && !props.isLegalRequiremendChecked} 
                                            className={"w-1/2 add-to-cart add-to-cart-xsmall " + (isTenantRequiredShootingLeaderPermission() && ((!isRangeSelector || selectedQty > 1 || selectedQtyType === 'upsell') && !props.parent?.tags?.find(x => x.key === 'throw') && !props.isLegalRequiremendChecked) ? " add-to-cart-disabled" : " add-to-cart-hover")} 
                                            onClick={() => {if (selectedVariant) {props.onForward(selectedVariant, selectedQty);         
                                                posthog.capture('merch add_to_cart', {
                                                    currency: currency,
                                                    value: (price / 100.00),
                                                    "items": [{
                                                        "item_id": props.parent?.sku ?? '',
                                                        "item_name": props.parent?.name ?? '',
                                                        "currency": currency,
                                                        "price": (price / 100.00),
                                                        "quantity": selectedQty
                                                    }]
                                                })
                                        }}}
                                        >
                                            <span className={"text font-semibold"}>{t('Dodaj do koszyka')}</span>
                                        </button>
                                    </div>
                                    <div className={"my-4 text"}>
                                    <span dangerouslySetInnerHTML={{ __html: props.variant?.product.description.replace(/(?:\r\n|\r|\n)/g, '<br>') ?? '' }} />
                                 
                                </div>
                            </div>
                            

                                                
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>)
}
export default RangeQtySelector
import { Variant } from "../models/Product.model"

export const RangeTypes = {
    BAY: 'bay',
    LANE: 'lane',
    DEFAULT: 'bay'
}

export const getType = (variant: Variant) => {
    if (isLane(variant)) {
        return RangeTypes.LANE
    }
    if (isBay(variant)) {
        return RangeTypes.BAY
    }
    return RangeTypes.DEFAULT
}
export const isLane = (variant: Variant | undefined) => {
    if (!variant) {
        return false;
    }
    return variant.value === 'Stanowisko' || variant.value === 'Bahn' || variant.value === 'Lane'
}
export const isBay = (variant: Variant | undefined) => {
    if (!variant) {
        return false;
    }
    return variant.value === 'Oś' || variant.value === 'Box' || variant.value === 'Bay'
}
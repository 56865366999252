import React, {useContext, useEffect, useState} from "react";
import './PackageUpsellDialog.scss'
import {BundleAttributes, SellableProduct, ExtensionAttributes, BundleProduct} from "../../models/Product.model";
import PackageUpsellDialogItem from "./PackageUpsellDialogItem";
import {CartContext, Types} from "../../contexts/reducers";
import {getFormattedPrice} from "../../utils/PriceUtils";
import {getPackageLabel} from "../../utils/UnitUtils";
import {useTranslation} from "react-i18next";
import { useCurrency } from "../../contexts/CurrencyProvider";
import { usePostHog } from "posthog-js/react";
import classNames from "classnames";
import { BiPlus } from "react-icons/bi";

type PackageUpsellDialogProps = {
    package: SellableProduct & BundleAttributes,
    extended: SellableProduct & ExtensionAttributes,
    premium: SellableProduct & ExtensionAttributes,
    isUpsellDialogOpen: boolean,
    onIsUpsellDialogOpenChange: (isUpsellDialogOpen: boolean) => void,
}
export const PackageUpsellDialog = (props: PackageUpsellDialogProps) => {
    const { state, dispatch } = useContext(CartContext);
    const {currency} = useCurrency()
    const {t} = useTranslation()
    const posthog = usePostHog()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [props.isUpsellDialogOpen])
    const getExtendedPackagePrice = (): number => {
        return props.extended.price
    }
    const getPremiumPackagePrice = (): number => {
        return props.premium.price
    }
    const selectProduct = (product: SellableProduct): void => {
        if (product.sku === props.premium.sku) {
            posthog.capture('merch upselled premium', {item_id: product.sku, item_name: product.name, price: product.price + props.package.price})
        } else if (product.sku === props.extended.sku) {
            posthog.capture('merch upselled extended', {item_id: product.sku, item_name: product.name, price: product.price + props.package.price})
        } else {
            posthog.capture('merch not_upselled', {item_id: product.sku, item_name: product.name, price: product.price})
        }
        dispatch({
            type: Types.Add,
            payload: [{
                sku: product.sku,
                type: product.type,
                quantity: 1,
                name: product.name,
                price: product.price + props.package.price,
                taxRate: product.taxRate,
                duration: product.duration ?? 0
            }]
        })
        props.onIsUpsellDialogOpenChange(false)
    }
    const [tiers, setTiers] = useState<Array<{name: string, id: string, price: string, description: string[], features: any[], mostPopular: boolean, action: () =>void}>>([])

    useEffect(() => {
        const standard = {
            name: t('Standard'),
            id: 'tier-standard',
            price: getFormattedPrice(props.package.price, true, currency),
            description: [t('Podstawowa zawartość')],
            features: [],
            mostPopular: false,
            action: () => selectProduct(props.package)
        }
        const extended = {
            name: t('Powiększony'),
            id: 'tier-extended',
            price: "+ " + getFormattedPrice( getExtendedPackagePrice(), true, currency),
            description: getPackageLabel(props.extended, [], 1, state.language).map((label, index) => (label + " " +t('więcej') + "!")),
            features:    props.extended.children.map(((weapon:BundleProduct) => <PackageUpsellDialogItem item={weapon} key={weapon.product.id}/>)),
            mostPopular: true,
            action: () => selectProduct(props.extended)
        }
        const premium = {
            name: t('Premium'),
            id: 'tier-premium',
            price:  "+ " + getFormattedPrice( getPremiumPackagePrice(), true, currency),
            description: getPackageLabel(props.premium, [], 1, state.language).map((label, index) => (label + " " + t('więcej') + "!")),
            features: props.premium.children.map(((weapon:BundleProduct) => <PackageUpsellDialogItem item={weapon} key={weapon.product.id}/>)),
            mostPopular: false,
            action: () => selectProduct(props.premium)
        }
        setTiers([standard, extended, premium])

    }, [props])
    return (
        <React.Fragment>
            {props.isUpsellDialogOpen &&
            <div className="p-dialog-mask p-component-overlay p-dialog-visible p-dialog-center"
                 style={{"zIndex": 1000, "backgroundColor":"rgba(240, 240, 240, 0.95)"}}>
                    <div className=" p-component relative w-full overflow-y-auto absolute p-1" style={{maxWidth:'1080px'}}>
                    <div className={"text-center font-bold text-3xl mt-8"}>{t('Powiększ wybrany pakiet')}</div>
                <div className="isolate mx-auto  grid max-w-md grid-cols-1 gap-y-3 md:gap-y-8 sm:mt-10 md:mt-18 mt-6 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                tierIdx === 0 ? 'lg:rounded-r-none' : '',
                tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '',
                'flex flex-col justify-between rounded-3xl bg-white py-4 md:py-8 ring-1 ring-gray-200 xl:py-10 relative lg:static',
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4 px-8 xl:px-10">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? 'text-secondary' : 'text-gray-900',
                      'text-xl font-semibold leading-8',
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-secondary-dimmed px-2.5 py-1 text-xs font-semibold leading-5 text-secondary">
                      {t("Polecany")}
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600 px-8 xl:px-10">{tier.description.map((d,index) => (<span key={index}>{d}</span>))}</p>
                <p className="mt-6 flex items-baseline gap-x-1 px-8 xl:px-10">
                  <span className="text-3xl font-semibold tracking-tight text-gray-900">{tier.price}</span>
                </p>
                <ul role="list" className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                    {tier.features.length > 0 && <span className="px-7 ">{t('W zestawie dodatkowo')}:</span>}
                  {tier.features.map((feature) => (
                    feature
                  ))}
                </ul>
              </div>
              <a
                onClick={tier.action}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-secondary text-white shadow-sm hover:bg-secondary'
                    : 'text-secondary ring-1 ring-inset ring-secondary hover:ring-secondary',
                  'mx-8 xl:mx-10 mt-3 md:mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary lg:static absolute top-12 right-0',
                )}
              >
                {t("Wybierz pakiet")}
              </a>
            </div>
          ))}
        </div></div>
            </div>}
        </React.Fragment>
    )
}

export default PackageUpsellDialog;

import {SellableProduct, SimpleProductAttributes} from "../../models/Product.model";
import React, {useState} from "react";
import {getImageUrl} from "../../utils/Api.service";
import {MdOutlineInfo} from "react-icons/md";
import LoadableImage from "../Image/LoadableImage";

type PackageInfoDialogItemProps = {
    item: SellableProduct & SimpleProductAttributes,
    onItemClicked: () => void
}
const PackageInfoDialogItemInfo = (props: PackageInfoDialogItemProps) => {
    const [image, setImage] = useState<string>('')
    const newImage = getImageUrl(props.item.sku, 'large')
    if (newImage !== image) {
        setImage(newImage)
    }

    return (
        <div onClick={props.onItemClicked}>
            <div className="cursor-pointer bg-white rounded-full text-dark hover:text-secondary absolute left-2 top-2 text-center flex justify-center">
                <MdOutlineInfo size={20}/>
            </div>
            <LoadableImage
                previewAvailable={false}
                classes={"mix-blend-multiply product-image my-auto mx-auto justify-center w-30 h-20 object-contain cursor-pointer"}
                thumbnailData={"data:" + props.item.thumbnailType + ";base64, " + props.item.thumbnail}
                imageSrc={image}/>
            <div className="text-sm font-bold">{props.item.name}</div>
        </div>
    )
}

export default PackageInfoDialogItemInfo

import React, { useContext, useEffect, useState } from "react"
import { RangeGroupProduct, SellableProduct } from "../../../models/Product.model"
import { Dialog } from "primereact/dialog"
import moment from "moment"
import { Button } from "primereact/button"
import { BiChevronRight, BiChevronLeft} from "react-icons/bi"
import { Calendar } from "primereact/calendar"
import {addLocale} from "primereact/api";
import { getProductAvailability } from "../../../utils/Api.service"
import {TailSpin} from "react-loader-spinner"
import {color} from "../../../utils/RealClient.service";
import {useTranslation} from "react-i18next";
import { calendarDe, calendarEn, calendarPl } from "../../../utils/CalendarLocale"
import { CartContext } from "../../../contexts/reducers"

type AvailabilityPopupProps = {
    isOpened: boolean,
    range:  SellableProduct & RangeGroupProduct|undefined,
    variantSku: string,
    onClose: () => void,
}
export const AvailabilityPopup = (props: AvailabilityPopupProps) => {
    const {t} = useTranslation()
    const {state} = useContext(CartContext)
    const dateFormat = 'dd DD.MM.YYYY';
    const [availability, setAvailability] = useState<Array<{time: string, availability: Array<boolean>}>>([])
    const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
    const [dateSelectorViewDate, setDateSelectorViewDate] = useState<Date>(moment().toDate());

    const [parsedDate, setParsedDate] = useState<string>(moment().format(dateFormat))
    const [isDateSelectorOpened, setIsDateSelectorOpened] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    addLocale('pl', calendarPl)
    addLocale('en', calendarEn)
    addLocale('de', calendarDe)

    const goNext = () => {
        if (selectedDate.diff(moment(), 'days') < 91) {
            selectDate(selectedDate.add(1, 'day'))  
        }
    }

    const goPrev = () => {
        if (selectedDate >= moment()) {
            selectDate(selectedDate.subtract(1, 'day'))
        }

    }

    const selectDate = (date: moment.Moment) => {
        setSelectedDate(date)
        setDateSelectorViewDate(date.toDate())
        setParsedDate(date.format(dateFormat))
        loadData(date)
    }

    const loadData = (date: moment.Moment) => {
        setIsLoading(true)
        getProductAvailability(props.variantSku ?? '', date.format('YYYY-MM-DD')).then(res => {
            setAvailability(res)
            setIsLoading(false)
        }).catch((e) => {
            setIsLoading(false)
            setAvailability([])
        })
    }

    useEffect(() => {   
        if (!props.range) { 
            return
        }
        loadData(selectedDate)
    }, [props.range])
    return (<React.Fragment>
         <Dialog 
            draggable={false}
            className="px-2 pb-2 bg-white  min-h-470"
            header={t("Wybierz termin")}
            dismissableMask={true}
            visible={isDateSelectorOpened}
            onHide={() => {
                setIsDateSelectorOpened(false)
            }}
            closable={true}>
                    <Calendar
                        numberOfMonths={1}
                        clearButtonClassName="hidden"
                        inline
                        locale={state.language.toLocaleLowerCase()}
                        className="border-0 px-4 pb-4"
                        value={selectedDate.toDate()} viewDate={dateSelectorViewDate}
                        onChange={(e: any) =>{selectDate(moment(e.value)); setIsDateSelectorOpened(false);}}
                        onViewDateChange={(e: any) => setDateSelectorViewDate(e.value)}
                        minDate={moment().toDate()} maxDate={moment().add(91, 'day').toDate()}
                        />
                    </Dialog>

        {props.range && 
        <Dialog blockScroll={true}
         dismissableMask={true}
          header={t("Dostępność") + " " + props.range.name.replace("Oś", "osi")} 
           visible={props.isOpened}
                className="availability-popup overflow-x-auto  overflow-y-auto min-h-100 bg-white px-2 lg:px-8 pb-8  relative"
                 onHide={() => {props.onClose(); selectDate(moment());}}
                contentClassName="overflow-x-auto"
                closable={true}>
                   
                    <div className="px-6 text-center text-lg mb-4">
                    <BiChevronLeft onClick={goPrev} className="hover:bg-secondary-dimmed hover:border-secondary cursor-pointer mr-2 text-primary shadow border inline-block p-1 rounded mb-2 hover:bg-white hover:text-blue" size={30}/>

                        <Button onClick={() => setIsDateSelectorOpened(true)} className="bg-white text-primary border-grey shadow rounded">{parsedDate}</Button>
                        
                        <BiChevronRight onClick={goNext} className="hover:bg-secondary-dimmed hover:border-secondary cursor-pointer ml-2 text-primary shadow border inline-block p-1 rounded mb-2 hover:bg-white hover:text-blue" size={30}/>
                        </div>
                    <div className="availability-legend avaialble"></div> {t('Stanowisko dostępne')}<br/>
                    <div className="availability-legend occupied"></div> {t('Stanowisko zajęte')}
                    <div className="relative">
                    {isLoading && 
                    <div className="px-6  absolute z-10 h-full w-full bg-white top-0 left-0">
                        <div className={"mx-auto mt-10"} style={{width: '80px'}}>
                        <TailSpin color={"#" + color} height={80} width={80}/>
                        </div>
                   </div>}
                   {availability.length > 0 &&
                    <table className="mt-4 availability-table">
                        <tbody>
                        <tr>
                            <th></th>
                            {availability[0].availability.map((el,index) => (
                                <th className="text-center" key={index}>{index+1}</th>
                            ))}
                        </tr>
                        {availability.map((aval,index) => (
                            <tr key={index}>
                                <td className="font-bold">{aval.time}</td>
                                {aval.availability.map((a,index2) => (
                                    <td key={index2}>{a ? <div className="bg-available h-8 w-8"></div>: <div className="bg-red h-8 w-8"></div>}</td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>}
                    {availability.length == 0 && <div className="text-xl mt-6 font-bold">{t('Brak dostępnych terminów')} <br/>{t('w wybranym dniu')}.</div>}

                    </div>
                   

        </Dialog>}

    </React.Fragment>)
}


import {Cart, CartError, CartItem, DiscountError} from "../models/Cart.model";
import ReactGA from "react-ga4";
import {DiscountResult} from "../models/Discount.model";
import {createContext, Dispatch, useState} from "react";
import {updateCart} from "../utils/Api.service";
import {TimeSlot} from "../models/TimeSlot.model";
import FatalErrorMessage from "../components/FatalErrorMessage/FatalErrorMessage";
import { RangeGroupProduct, SellableProduct } from "../models/Product.model";
import {SupportedLanguages} from "../utils/Languages";
import {SupportedCurrencies} from "../utils/Currencies";
import detectLanguage from "../utils/LanguageDetector";
import { useCurrency } from "./CurrencyProvider";
import { usePostHog } from "posthog-js/react";
import ReactPixel from 'react-facebook-pixel';

type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
            type: Key;
        }
        : {
            type: Key;
            payload: M[Key];
        }
};

export enum Types {
    Init = 'INIT',
    Add = 'ADD_PRODUCT',
    Delete = 'DELETE_PRODUCT',
    Update = 'UPDATE_PRODUCT',
    AddDiscount = 'ADD_DISCOUNT',
    DeleteDiscount = 'DELETE_DISCOUNT',
    UpdateCart = 'UPDATE',
    ReplaceCart = 'REPLACE',
    UpdateCartItemTimeSlot = 'UPDATE_CART_ITEM_TIMESLOT',
    SelectOrderType = 'SELECT_ORDER_TYPE',
    UpdateTimeslot = 'UPDATE_TIMESLOT',
    UpdateDuration = 'UPADTE_DURATION',
    RemoveTimeslot = 'REMOVE_TIMESLOT',
    UpdateSource = 'UPDATE_SOURCE',
    SelectRange = 'SELECT_RANGE',
    RemoveSelectedRange = 'REMOVE_SELECTED_RANGE',
    UpdateLocals = 'UPDATE_LOCALS',
}

type CartInitPayload = {
    code?: string,
    source?: string,
}

type CartPayload = {
    [Types.Init]: CartInitPayload,
    [Types.Add]: CartItem[];
    [Types.Delete]: CartItem,
    [Types.Update]: { item: CartItem, index: number }
    [Types.AddDiscount]: DiscountResult,
    [Types.DeleteDiscount]: DiscountResult,
    [Types.UpdateCart]: Cart,
    [Types.ReplaceCart]: Cart,
    [Types.UpdateTimeslot]: TimeSlot,
    [Types.UpdateDuration]: number,
    [Types.UpdateSource]: string,
    [Types.SelectOrderType]:string,

    [Types.SelectRange]: SellableProduct,
    [Types.RemoveTimeslot]: null,
    [Types.RemoveSelectedRange]: null,
    [Types.UpdateCartItemTimeSlot]: {index: number, timeslot: TimeSlot},
    [Types.UpdateLocals]: {language: SupportedLanguages, currency: SupportedCurrencies},
}
type AppState = {
    cart: Cart,
    booking: TimeSlot | undefined,
    orderType: string,
    calendarActive: boolean,
    selectedRange: SellableProduct & RangeGroupProduct | undefined,
    language: SupportedLanguages,
    currency: SupportedCurrencies,
}


export type CartActions =
    ActionMap<CartPayload>[keyof ActionMap<CartPayload>];

// @ts-ignore
function useAsyncReducer(reducer, initState) {
    const [state, setState] = useState(initState),
        dispatch = async (action: CartActions) => setState(await reducer(state, action));
    return [state, dispatch];
}

const calculateCartValue = (items: CartItem[]) => {
    return  items.reduce<number>((acc, item) => {
        return acc + item.price * item.quantity + (item.upsell ?? []).reduce<number>((acc2, upsell) => {
            return acc2 + upsell.quantity * upsell.price
        }, 0) ?? 0
    }, 0)

}


const initialState: AppState = {
    booking: undefined,
    cart: {
        id: '',
        price: 0,
        basePrice: 0,
        duration: 0,
        items: new Array<CartItem>(),
        discounts: new Array<DiscountResult>(),
        source: '',
    },
    calendarActive: true,
    orderType: '',
    selectedRange: undefined,
    language: detectLanguage(),
    currency: SupportedCurrencies.ZLOTY,

}

const CartContext = createContext<{
    state: AppState;
    getTotalCount: ()=>number,
    dispatch: Dispatch<CartActions>;
}>({
    state: initialState,
    dispatch: () => null,
    getTotalCount: () => 0,
});

interface Props {
    children: React.ReactNode;
  }

  
const CartProvider: React.FC<Props> = ({ children }) => {
    const isCalendarActive = (cart: Cart) => {
        const cartItemNumbers = cart.items.length + (cart.discounts?.filter(x => x.type === 'BUNDLE_VOUCHER').length ?? 0)
        const nonBookableItems = cart.items.filter(item => (item.type === 'CONFIGURABLE' || item.type === 'MONEY_VOUCHER' || item.type === 'VARIANT')).length
        return cartItemNumbers > 0 && nonBookableItems < cartItemNumbers
    }
    const {currency} = useCurrency()
    const posthog = usePostHog()

     const cartItemReducer = async (
        state: AppState,
        action: CartActions
    ) => {
        switch (action.type) {

            case Types.Init:
                return state
            case Types.Add:
                action.payload.forEach((item) =>{
                    ReactGA._gtag("event", "add_to_cart", {
                        currency: currency,
                        value: (item.price / 100.00),
                        "items": [{
                            "item_id": item.sku,
                            "item_name": item.name,
                            "currency": currency,
                            "price": (item.price / 100.00),
                            "quantity": item.quantity
                        }]
                    })
                    posthog.capture('merch add_to_cart', {
                        currency: currency,
                        value: (item.price / 100.00),
                        "items": [{
                            "item_id": item.sku,
                            "item_name": item.name,
                            "currency": currency,
                            "price": (item.price / 100.00),
                            "quantity":item.quantity
                        }]
                    })
                    ReactPixel.track('AddToCart', {
                        contentName: item.name,
                        content_ids:[item.sku],
                        content_type: 'product',
                        value:  (item.price / 100.00),
                        currency: currency
                    })
                 }
                )
                
                let errorObject: (CartError | null) = null

                if (state.cart.items?.length ?? 0 > 0) {
                    const newCartState = [...state.cart.items]
                    action.payload.forEach(cartItem => {
                        const index =  newCartState.findIndex(x=> 
                            x.sku === cartItem.sku 
                            && ((!x.upsell && !cartItem.upsell) || 
                            (JSON.stringify(x.upsell?.map(x => ({s:x.sku, q:x.quantity}))) === JSON.stringify(cartItem.upsell?.map(x => ({s: x.sku, q:x.quantity}))))))
                        if ((cartItem.type === 'BUNDLE' || cartItem.type === 'EXTENSION') && !cartItem.timeslot && index >= 0) {
                            newCartState[index].quantity += cartItem.quantity
                        }  else {
                            newCartState.push(cartItem)
                        }
                    })
                    const cartWithNewItem = await updateCart({
                        ...state.cart,
                        items: newCartState,
                    }).catch((error) => {
                        setErrorMessage(error.errors.items.reduce((acc:string, elem: DiscountError) => (acc + " " + elem.message), ""))
                        setDialogVisible(true)
                        errorObject = error
                        return state.cart
                    })
                    if (!isCalendarActive(cartWithNewItem)) {
                        return {
                            ...state,
                            calendarActive: false,
                            booking: null,
                            cart: cartWithNewItem,
                            selectedRange: state.selectedRange
                        }
                    } else {
                        return {
                            ...state,
                            calendarActive: true,
                            booking: null,
                            cart: cartWithNewItem,
                            selectedRange: state.selectedRange
                        }
                    }
                } else {
                    const cartWithNewItem = await updateCart({
                        ...state.cart,
                        items: action.payload,
                    }).catch((error) => {
                        setErrorMessage(error.errors.items.reduce((acc:string, elem: DiscountError) => (acc + " " + elem.message), ""))
                        setDialogVisible(true)
                        errorObject = error
                        return state.cart
                    })
                    if (!isCalendarActive(cartWithNewItem)) {
                        return {
                            ...state,
                            calendarActive: false,
                            booking: null,
                            cart: cartWithNewItem,
                            selectedRange: state.selectedRange
                        }
                    } else {
                        return {
                            ...state,
                            calendarActive: true,
                            booking: null,
                            cart: cartWithNewItem,
                            selectedRange: state.selectedRange
                        }
                    }
                }
            case Types.Delete:
                ReactGA._gtag("event", "remove_from_cart", {
                    currency: currency,
                    value: (action.payload.price / 100.00),
                    "items": [{
                        "item_id": action.payload.sku,
                        "item_name": action.payload.name,
                        "currency": currency,
                        "price": (action.payload.price / 100.00),
                        "quantity": 1
                    }]
                })
                ReactPixel.track('RemoveFromCart', {
                    contentName: action.payload.name,
                    content_ids:[action.payload.sku],
                    content_type: 'product',
                    value:  (action.payload.price / 100.00),
                    currency: currency
                })
                posthog.capture('merch remove_from_cart', {
                    currency: currency,
                    value: (action.payload.price / 100.00),
                    "items": [{
                        "item_id": action.payload.sku,
                        "item_name": action.payload.name,
                        "currency": currency,
                        "price": (action.payload.price / 100.00),
                        "quantity": 1
                    }]
                })
                const items = state.cart.items?.filter(item => item !== action.payload)
                const cartWithoutItem = await updateCart({
                    ...state.cart,
                    items: items,
                })
                if (isCalendarActive(cartWithoutItem)) {
                    return {
                        ...state,
                        calendarActive: true,
                        booking: state.booking,
                        cart: cartWithoutItem,
                        selectedRange: state.selectedRange
                    }
                } else {
                    return {
                        ...state,
                        calendarActive: false,
                        booking: null,
                        cart: cartWithoutItem,
                        selectedRange: state.selectedRange
                    }
                }
            case Types.ReplaceCart:
                
                const cartUpdated = await updateCart({
                    ...state.cart,
                    ...action.payload,
                    price: calculateCartValue(state.cart.items)
                })
                return {
                    ...state,
                    booking: null,
                    cart: cartUpdated,
                    calendarActive: isCalendarActive(cartUpdated),
                    selectedRange: state.selectedRange
                }
            case Types.Update:
                const cartProducts = state.cart.items
                cartProducts[action.payload.index] = action.payload.item
                const cartAfterUpdate = await updateCart({
                    ...state.cart,
                    items: cartProducts,
                    price: calculateCartValue(cartProducts)
                }).catch((error) =>         
                { return error.fixedCart})
                return {
                    ...state,
                    booking: null,
                    cart: cartAfterUpdate,
                    calendarActive: isCalendarActive(cartAfterUpdate),
                    selectedRange: state.selectedRange
                }
            case Types.SelectOrderType:
                return {
                    ...state,
                    orderType: action.payload
                }
            case Types.AddDiscount:
                const discount = (state.cart.discounts ?? []).filter(x => x.code !== action.payload.code)
                discount.unshift(action.payload)
                try {
                const updatedCart = await updateCart({
                    ...state.cart,
                    discounts: discount
                })
               
                const updatedDiscount = updatedCart.discounts.find(x => x.code === action.payload.code)
                if (updatedDiscount) {
                    ReactGA._gtag("event", "selected_promotion", {
                        creative_name: updatedDiscount.type,
                        promotion_id: updatedDiscount.code,
                    })
                    if (updatedDiscount.type === 'BUNDLE_VOUCHER'){
                        posthog.capture('merch voucher_applied')
                    } else {
                        posthog.capture('merch coupon_applied', {code: updatedDiscount.code})
                    }
                    
                }
                return {
                    ...state,
                    booking: null,
                    cart: updatedCart,
                    calendarActive: isCalendarActive(updatedCart),
                    selectedRange: state.selectedRange
                }
                } catch (e) {
                    console.log(e)
                    return
                }
            case Types.DeleteDiscount:
                const cartWithDeletedItem = await updateCart({
                    ...state.cart,
                    discounts: state.cart.discounts?.filter(discount => discount.code !== action.payload.code)
                })
                return {
                    ...state,
                    booking: {...state.booking, duration: cartWithDeletedItem.duration},
                    cart: cartWithDeletedItem,
                    calendarActive: isCalendarActive(cartWithDeletedItem),
                    selectedRange: state.selectedRange
                }
            case Types.UpdateCart:
                return {
                    ...state,
                    cart: action.payload,
                    booking: {...state.booking, duration: action.payload.duration},
                    calendarActive: isCalendarActive(action.payload),
                    selectedRange: state.selectedRange
                }
            case Types.UpdateTimeslot:
                var type = 'booking'
                if (action.payload.type === 'voucher') {
                    type = 'voucher'
                }
                return {
                    ...state,
                    orderType: type,
                    booking: {...action.payload, duration: state.cart.duration},
                    calendarActive: isCalendarActive(state.cart),
                    selectedRange: state.selectedRange
                }
                return
            case Types.UpdateDuration:
                return {
                    ...state,
                    booking: {...state.booking, duration: action.payload},
                    calendarActive: isCalendarActive(state.cart),
                    selectedRange: state.selectedRange
                }
                return
            case Types.RemoveTimeslot:
                return {
                    ...state,
                    booking: undefined,
                    calendarActive: isCalendarActive(state.cart),
                    selectedRange: state.selectedRange
                }
                return
            case Types.UpdateSource:
                const cartWithSource = await updateCart({
                    ...state.cart, source: action.payload,
                })
                return {
                    ...state,
                    cart: cartWithSource,
                    booking: state.booking,
                    calendarActive: isCalendarActive(cartWithSource),
                    selectedRange: state.selectedRange
                }
            case Types.SelectRange:
                return {
                    ...state,
                    selectedRange: action.payload
                }
            case Types.RemoveSelectedRange:
                return {
                    ...state,
                    selectedRange: undefined
                }
            case Types.UpdateCartItemTimeSlot:
                const cartItems = state.cart.items
                const updatedCartItem = state.cart.items[action.payload.index]
                if (action.payload.timeslot.type === 'timeslot') {
                updatedCartItem.timeslot = {
                    date: action.payload.timeslot.day,
                    time: action.payload.timeslot.time,
                    //@ts-ignore
                    calendarIds: action.payload.timeslot.calendarIds
                }
            }
               
                cartItems[action.payload.index] = updatedCartItem
                const cartWithUpdatedTimeSlot = await updateCart({
                    ...state.cart,
                    items: cartItems
                })
                return {
                    ...state,
                    cart: cartWithUpdatedTimeSlot,
                }
            case Types.UpdateLocals:
                    return {
                        ...state,
                        language: action.payload.language,
                        currency: action.payload.currency
                    }
    
            default:
                return state;
        }
    }
    // @ts-ignore
    const [state, dispatch] = useAsyncReducer(cartItemReducer, initialState);
    const [dialogVisible, setDialogVisible] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    const getTotalCount = () => {
        let total = 0
        state.cart.discounts.filter((x: DiscountResult) => x.type === 'BUNDLE_VOUCHER').forEach((item : CartItem) => total += item.quantity)
        state.cart.items.forEach((item : CartItem) => total += item.quantity)
        return total
    }
    return (
        <CartContext.Provider value={{state, dispatch, getTotalCount}}>
            {children}
            <FatalErrorMessage
                dialogVisible={dialogVisible}
                setDialogVisible={setDialogVisible}
                errorMessage={errorMessage}
            />
        </CartContext.Provider>
    )
}
export {CartProvider, CartContext};
import React from 'react';
import * as Sentry from "@sentry/react";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import detectLanguage from "./utils/LanguageDetector";
import moment from "moment";
import 'moment/locale/pl'
import 'moment/locale/en-gb'
import 'moment/locale/de'
import yaml from 'js-yaml';
import { createRoot } from 'react-dom/client';
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'

posthog.init('phc_lEhastFUOFQJSaqMdReWjzhFycKf4UFrZBFH2YwKlz', { api_host: 'https://eu.posthog.com',persistence: 'memory', autocapture: false})

Sentry.init({
    dsn: "https://fda6b8fdcc3546e6962ff542d39af846@o1115735.ingest.sentry.io/6148075",
    integrations: [
        Sentry.replayIntegration(),
      ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
});
const language = detectLanguage().toLowerCase()
moment.locale(language === 'en' ? 'en-gb' : language)
i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.yaml',
            parse: function (data: any) { return yaml.load(data)},

        },
        react: {
            useSuspense: false,
        },
        saveMissing: false,
        ns: ['bookitone'],
        defaultNS: 'bookitone',
        lng: language, // if you're using a language detector, do not define the lng option
        fallbackLng: "pl",
        debug: false,
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });


    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(<React.StrictMode>
        <PostHogProvider client={posthog}>
            <App />
        </PostHogProvider>
      </React.StrictMode>);

reportWebVitals();

import {Dialog} from "primereact/dialog";
import {BiCrosshair} from "react-icons/bi";
import {ReactElement} from "react";
import {useTranslation} from "react-i18next";

type FatalErrorMessageProps = {
    dialogVisible: boolean,
    setDialogVisible: (value: boolean) => void,
    errorMessage: string
    children?: ReactElement
}
const FatalErrorMessage = (props: FatalErrorMessageProps) => {
    const {t} = useTranslation()
    return (
        <Dialog onHide={() => props.setDialogVisible(false)} visible={props.dialogVisible} header={<div className={"text-left"}>{t('Pudło')}!</div>}>

            <div className={"p-4 pt-0 pb-8 px-10 text-center"}>
                <BiCrosshair color={"#E74C3C"} size={80} className={"mx-auto mb-4"}/>
               {t('Wystąpił problem.')}<br/>
                {props.errorMessage}<br/>
                {t('Jeżeli uniemożliwia on realizację zamówienia skontaktuj się z nami')}: <a className="text-secondary" href={"mailto:kontakt@bookit.one"}>kontakt@bookit.one</a>
                {props.children}
            </div>

        </Dialog>
    )
}
export default FatalErrorMessage
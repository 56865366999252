import React, {CSSProperties, useState,useCallback} from "react"
import classNames from "classnames";
import ImageViewer from "react-simple-image-viewer";

type LoadableImageProps = {
    classes: string,
    thumbnailData: string,
    imageSrc: string,
    style?: CSSProperties,
    height?: number,
    width?: number
    previewAvailable: boolean,
}

export const LoadableImage = (props: LoadableImageProps) => {
    const [mainImageLoaded, setMainImageLoaded] = useState<boolean>(false);

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const onMainImageLoaded = () => {
        setMainImageLoaded(true);
    }

    const openImageViewer = useCallback((index: number) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);
    
      const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };

    return (
        <React.Fragment>
            <div >
                <img  width={props.width ?? ""} height={props.height ?? ""}  className={classNames({"hidden ": mainImageLoaded}) + props.classes + " thumbnail filter blur transition-all bg-center cover"} src={props.thumbnailData} style={props.style}/>
                <img onClick={() => {props.previewAvailable && openImageViewer(0)}} width={!mainImageLoaded ? 0 : props.width ?? ""} height={!mainImageLoaded ? 0 : props.height ?? ""} className={(mainImageLoaded ? " " : "w-0i h-0i " ) + props.classes + " " + (props.previewAvailable ? " cursor-pointer " : "")} src={props.imageSrc} style={ mainImageLoaded ? props.style : {}} onLoad={onMainImageLoaded}/>
            </div>
            {isViewerOpen && (
                <ImageViewer
                src={[props.imageSrc]}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)"
                }}
                closeOnClickOutside={true}
                />
            )}
        </React.Fragment>
    )
}

export default LoadableImage
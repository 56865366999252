export const getFormattedPrice = (price: number, fractionDigits = true, currency: string = 'PLN') => {
    const value = (price / 100.00)
    var lang = 'pl-PL'
    if (currency === 'EUR') {
        lang = 'de-DE'
    }
    return new Intl.NumberFormat(lang, { 
        style: 'currency', 
        currency: currency, 
        minimumFractionDigits: (fractionDigits || value % 1 !== 0 ) ? 2 : 0,
        maximumFractionDigits: (fractionDigits || value % 1 !== 0 ) ? 2 : 0,
    }).format(
        value,
      )
}
import moment, {Moment} from "moment";

export const DEFAULT_DATE_FORMAT = "DD-MM-YYYY";

export const getDaysBetween = (startDate: moment.Moment, endDate: moment.Moment): moment.Moment[] => {
    const now = startDate.clone()
    const dates: moment.Moment[] = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.clone())
        now.add(1, 'days');
    }
    return dates;
};
export const inverseDatesInBounds = (dates: moment.Moment[], min: moment.Moment, max: moment.Moment): moment.Moment[] =>
    getDaysBetween(min, max)
        .filter(day => !dates.find(day2 => day.format('YYYY-MM-DD') === day2.format('YYYY-MM-DD')))

export const isSameDayInStringAndDate = (date1: Moment, date2: Moment) => moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD')

export const parseDate = (dateString: string) => moment(dateString, DEFAULT_DATE_FORMAT)

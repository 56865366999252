import React, {useContext, useState} from 'react';
import {MdClose, MdOutlineInfo} from "react-icons/md";
import {WeaponDialog} from "./WeaponDialog";
import {CartItem} from "../../models/Cart.model";
import {getImageUrl} from "../../utils/Api.service";
import {getCartItemLabel} from "../../utils/UnitUtils";
import { CartContext } from '../../contexts/reducers';

type PackageAdditionsProps = {
    addition: CartItem,
    onAdditionDelete: () => void
}

export const PackageAddition = (props: PackageAdditionsProps) => {
    const [image, setImage] = useState<string>('')
    const newImage = getImageUrl(props.addition.sku, 'large')
    const {state} = useContext(CartContext)
    if (newImage !== image) {
        setImage(newImage)
    }
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
    return (
        <React.Fragment>
            <div key={props.addition.sku} className="min-w-24 w-28 sm:w-32 inline-block mb-4">

                <div className="relative bg-white border-secondary border-2 rounded ml-2 mr-2 mt-4 text-center rounded px-2 align-top">
                    <div
                        onClick={props.onAdditionDelete}
                        className="cursor-pointer text-dark hover:text-white hover:bg-secondary absolute -right-4 -top-4 rounded-full bg-white border-2  border-secondary w-10 h-10 leading-6 text-center flex justify-center z-10">
                                            <span>
                                            <MdClose size={20} className={"mt-2"}/>
                                            </span>
                    </div>
                    <div className="cursor-pointer product-description" onClick={(e) => setIsDialogOpened(true)}>
                        <div className="cursor-pointer bg-white rounded-full text-dark hover:text-secondary absolute left-2 top-2 text-center flex justify-center">
                            <MdOutlineInfo size={20}/>
                        </div>
                        <img className="product-image my-auto mx-auto justify-center w-20 h-20 object-contain" src={image} height="320"/>
                        <div className="font-bold">{props.addition.name}</div>
                        <div className={"justify-center mb-2"}>
                            <div className="text-sm inline-block mx-2">
                                { //@ts-ignore
                                    getCartItemLabel(props.addition, state.language).map((label, index) => (
                                       label
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WeaponDialog isDialogOpen={isDialogOpened} onHide={() => setIsDialogOpened(false)} item={{
                type: "SIMPLE",
                id: '',
                sku: props.addition.sku,
                name:props.addition.name ?? '',
                price: 0,
                taxRate: "0%",
                description: props.addition.description ? props.addition.description : "",
                unit: '',
                quantityMultiplier: 1.,
                thumbnailType: '',
                thumbnail: ''
            }}/>
        </React.Fragment>
    )
}

export default PackageAddition;

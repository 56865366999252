import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import {BsCash} from "react-icons/bs";

import {TiTick} from "react-icons/ti";
import { RiSecurePaymentLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useTenant } from '../../contexts/TenantProvider';

type PaymentMethodsProps = {
    selectedPaymentMethod?: string,
    onPaymentMethodSelected: (selectedPaymentMethod: string) => void
}
const PaymentMethods = (props: PaymentMethodsProps) => {
    const {t} = useTranslation()
    const {tenant} = useTenant()

    useEffect(() => {
        const payments = []
        if (tenant.paymentMethods.find(x => x === 'ONLINE')) {
            payments.push({
                name: t('Online'),
                icon: <RiSecurePaymentLine size="50" className="text-primary"/>,
                id: 'ONLINE'
            })
        }
        if (tenant.paymentMethods.find(x => x === 'CASH')) {
            payments.push({
                name: t('Na miejscu'),
                icon: <BsCash size="50" className="text-primary"/>,
                id: 'CASH'
            })
        }
        setPaymentMethods(payments)
        props.onPaymentMethodSelected(payments[0].id)
    }, [tenant])

    const [paymentMethods,setPaymentMethods] = useState<Array<{name: string, icon: any, id: string}>>([])
    return (
        <React.Fragment>
            <div className="sm:flex">
                <input name={"paymentMethod"} type={"hidden"}/>
                    {
                        paymentMethods.map(elem =>
                            <div className="mb-4 sm:mb-0 w-full sm:max-w-48 sm:h-36  sm:inline-block" key={elem.id}>
                                <div className={classNames({
                                    "mr-2 relative h-full rounded hover:cursor-pointer": true,
                                    "bg-secondary-dimmed border-2 border-secondary": props.selectedPaymentMethod === elem.id,
                                    "bg-grey-100 border-2 border-grey-100": props.selectedPaymentMethod !== elem.id,
                                })}
                                     onClick={() => props.onPaymentMethodSelected(elem.id)}
                                >
                                    <div className="flex justify-center mt-4 sm:mt-8 mb-10 sm:mb-0">{elem.icon}</div>
                                    <div className="absolute bottom-4 text-center font-bold w-full">{elem.name}</div>
                                    {props.selectedPaymentMethod === elem.name &&
                                    <div
                                        className="absolute -right-4 -top-4 rounded-lg  bg-white border-2  border-secondary w-12 h-12 leading-8 text-center flex justify-center">
                                        <TiTick size="22" className="my-auto"/>
                                    </div>
                                    }
                                </div>
                            </div>
                        )
                    }
            </div>
        </React.Fragment>
    );
};

export default PaymentMethods;

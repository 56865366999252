import {SupportedLanguages} from "./Languages";

const detectLanguage = () => {
    var memory = ''
    try {
        memory = localStorage.getItem('lang') ?? ''
    } catch (e) {

    }
    if (!memory) {
        // @ts-ignore
        memory = window.navigator.userLanguage || window.navigator.language;   
    }
    if (memory === 'PL_PL' || memory === 'pl_PL' || memory ==='pl' || memory === 'pl-PL'){
        return SupportedLanguages.PL
    } else if (memory === 'DE_DE' || memory === 'de' || memory==='de_DE' || memory === 'de-DE'){
        return SupportedLanguages.DE
    } else {
        return SupportedLanguages.EN
    }
}

export default detectLanguage
